import { AccordionProps, AccordionSummaryProps, styled } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid #15998E`,
  background: 'none',
  marginTop: '10px !important',
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root': {
    width: '100%',
    padding: '0px'
    
  },
  '& .MuiAccordionSummary-content': {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  '& .MuiCollapse-root': {
    padding: '0px',
    width: '100%',
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  background: 'none',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'none',
  }),
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),

  background: 'none',

  '&.MuiAccordionDetails-root': {
    padding: '10px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '7.5px',
  }
}));
import React, { useEffect } from 'react';
import './style/App.scss';
import './style/Layout.scss';
import 'bootstrap/dist/css/bootstrap.css';
import {useDispatch} from 'react-redux';
import * as authActions from './store/auth/actions';
import {Outlet, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Dashboard from './coachApp/dashboard/Dashboard';
import Clients from './coachApp/clients/Clients';
import Programs from './coachApp/programs/Programs';
import Programlist from './coachApp/programs/containers/Programlist';
import ProgramComponent from './coachApp/programs/containers/Program';
import Schedule from './coachApp/schedule/Schedule';
import { Route } from 'react-router-dom';
import ClientApp from './clientApp/ClientApp';
import CoachApp from './coachApp/CoachApp';
import ClientLoginForm from './landing/ClientLogin';
import CoachLogin from './landing/CoachLogin';
import ClientProgramHead from './clientApp/program/components/ClientProgramHead';
import ClientProgram from './clientApp/program/ClientProgram';
import ClientWorkout from './clientApp/program/components/ClientWorkout';
import PrintPage from './program-print/PrintPage';
import Layout from './Layout';
import Templatelist from './coachApp/templates/TemplateList';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { setMobile } from './store/app/actions';
import Account from './coachApp/account/Account';
import { getAccount } from './+store/account/actionTypes';
import {getClient, getClients} from './+store/clients/actionTypes';
import AssessmentDetailPage from './coachApp/assessments/Assessment';
import AssessmentList from './coachApp/assessments/AssessmentList';
import { assessmentActions } from './+store/assessments/actionTypes';
import store from './store/store';
import { getDefinitions } from './+store/definitions/inputs/actionTypes';
import Client from './coachApp/clients/Client';
import { getEvents } from './+store/events/actionTypes';
import { templateActions } from './+store/templates/actionTypes';
import { programActions } from './+store/programs/actionTypes';

export default function App(props: any) {
  const login_screen = localStorage.getItem('login_page');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.checkAuthState());
  }, []);

  useEffect(() => {
    dispatch(setMobile(isMobile));
  }, [isMobile]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<span>Damn</span>} element={<Layout isMobile={isMobile} />}>
        <Route path="coachlogin" element={<CoachLogin />} />
        <Route path="clientlogin" element={<ClientLoginForm />} />
        <Route element={<CoachApp />} path="coach">
          <Route index loader={() => {
            dispatch(programActions.getPrograms(''))
            return  dispatch(getEvents(''))
          }} element={<Schedule />} />
          <Route element={<Dashboard />} path="dashboard" />
          <Route element={<Programs />} loader={() => {
                dispatch(getDefinitions());
                dispatch(getClients(''));
                return dispatch(programActions.getPrograms(''));
          }} path="programs">
            <Route index element={<Programlist />} />
            <Route
              path=":programId/:weekNo"
              element={<ProgramComponent history={props.history} client={false} />}
            />
            <Route
              path=":programId/:weekNo/:workout_id/:session_id"
              element={<ProgramComponent history={props.history} client={false} />}
            />
            <Route path=":programId/print" element={<PrintPage client={false} />} />
          </Route>
          <Route path="templates" loader={() => {
            dispatch(getDefinitions());
            dispatch(getClients(''));
            return dispatch(templateActions.getTemplatesRequest(''));
          }} element={<Templatelist />} />
          <Route path="clients" loader={() => {
            return dispatch(getClients(''));
          }} element={<Outlet />} >
            <Route index element={<Clients />} />
            <Route path=":client_id" loader={(data) => dispatch(getClient(Number(data.params.client_id)))} element={<Client />} />
          </Route>
          <Route path="schedule" loader={() => {
            dispatch(programActions.getPrograms(''))
            return  dispatch(getEvents(''))
          }} element={<Schedule />} />
          <Route path="account" loader={(data) => dispatch(getAccount())}  element={<Account />} />
          <Route path="assessments" element={<Outlet />} >
            <Route index loader={(data) => { 
              dispatch(getClients(''));
              return dispatch(assessmentActions.getAssessments())
              }} element={<AssessmentList />} />
            <Route 
              path=":assessment_id" 
              loader={(data) => {
                dispatch(getClients(''));
                dispatch(getDefinitions())
                return dispatch(assessmentActions.getAssessment(Number(data.params.assessment_id)))}}
              element={<AssessmentDetailPage />} />
          </Route>
        </Route>
        <Route element={<ClientApp />}  path="client">
          <Route element={<ClientProgram />}  path="programs">
            <Route index element={<Programlist />} />
            <Route path=":programId"  element={<ClientProgramHead />} />
            <Route path=":programId/:workout_id/:session_id" loader={() => dispatch(getDefinitions())} element={<ClientWorkout />} />
            <Route path="templates" element={<Templatelist />} />
          </Route>
          <Route path="program/:programId/print" element={<PrintPage client={true} />} />
        </Route>
        <Route index element={login_screen === "CLIENT" ? <ClientLoginForm /> : <CoachLogin />} />
      </Route>
    )
  );
  return (
    <RouterProvider router={router} />
    
  );
}

import { ListUserInterface } from "./userTypes";
import { WorkoutDetailInterface, WorkoutType } from './workoutTypes';

export class ProgramConfigSession {
  workout_id: number = -1;
  session_id: number = -1;
  date_time: string = new Date().toISOString();
  constructor(partial?: ProgramConfigSession) {
    if (partial) {
      Object.assign(this, partial);
    }
  }
}
export enum Day {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}
export type WeekConfig = { [key: number]: ProgramConfigSession[] };
export class ProgramConfigClass {
  public no_weeks: number = 1;
  public plan: WeekConfig[] = [
    {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    },
  ];
  constructor(partial?: ProgramConfigClass) {
    if (partial) {
      Object.assign(this, partial);
    }
  }
}
export interface ProgramBaseInterface {
  creator: ListUserInterface;
  client: ListUserInterface[];
  id: number;
  macro: string;
  meso: string;
  modified?: Date;
  created?: Date;
  name: string;
  quadrennial: string;
  start: Date;
  viewers: ListUserInterface[] | null;
}
export interface PostProgramInterface {
  creator: number;
  client: number[];
  id?: number;
  macro: string;
  meso: string;
  modified?: Date;
  name: string;
  quadrennial: string;
  start: Date;
  viewers: number[] | null;
  program_config: ProgramConfigClass;
}

export class PostProgramClass {
  public creator: number = -1;
  public client: number[] = [];
  public macro: string = '1';
  public meso: string = '1';
  public name: string = 'program';
  public quadrennial: string = '2024';
  public start: Date = new Date();
  public viewers: number[] = [];
  public program_config: ProgramConfigClass = new ProgramConfigClass();

  constructor(partial?: PostProgramClass) {
    Object.assign(this, partial);
  }
}
export interface ProgramInterface extends ProgramBaseInterface {
  workouts: { id: number; order: number }[];
  program_config: ProgramConfigClass;
}
export class ProgramClass<ProgramInterface> {
  constructor(partial: ProgramInterface) {
    Object.assign(this, partial);
  }
}
export interface ProgramDetailInterface extends ProgramBaseInterface {
  workouts: WorkoutDetailInterface[];
  program_config: ProgramConfigClass;
}

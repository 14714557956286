import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../../style/Coach/ProgramForm.scss';
import * as appActions from '../../../../store/app/actions';
import { RootState } from '../../../../store/store';
import { clientSelectors } from '../../../../+store/clients/reducers';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  CircularProgress,
  Input,
  ListItemText,
  Checkbox,
  Tooltip,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { programActions } from '../../../../+store/programs/actionTypes';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ProgramConfigClass, ProgramConfigSession, ProgramInterface } from '../../../../tsUtils';
interface Props {
  program?: ProgramInterface;
  edit?: boolean;
}
type Option = {
  label: string;
  value: number;
};
interface ProgramForm {
  name: string;
  quadrennial: string;
  client: number[];
  macro: string;
  meso: string;
  viewers: number[];
  start: Date;
}
export interface PromptQuestions {
  instructions: string;
  goals: string[];
  experience: string;
  no_weeks: number;
  session_length: number;
  frequency: number;
  sport: string;
}

const no_weeks = [1,2,3,4];
const frequency = [1,2,3,4,5];

// trigger animation when user selects AI or Manual
const transitionStyle1 = (aiForm: boolean) => {
  if (aiForm) {
    return {
      transition: 'all 0.5s ease-in-out',
      transform: 'translateX(-50%)',
    };
  } else {
    return {
      transition: 'all 0.5s ease-in-out',
      transform: 'translateX(0)',
    };
  }
};

function CreateProgramForm(props: Props) {
  const clients = useSelector((state: RootState) => clientSelectors.selectAll(state));
  const loading = useSelector((state: RootState) => state.programs.loading);
  const loaded = useSelector((state: RootState) => state.programs.loaded);
  const [submitting, setSubmitting] = React.useState(false)
  const user = useSelector((state: RootState) => state.auth.user);
  const [aiForm, setAiForm] = React.useState(false);
  const [promptQuestions, setPromptQuestions] = React.useState<PromptQuestions>({
    instructions: '',
    goals: [],
    experience: '',
    no_weeks: 1,
    session_length: 45,
    frequency: 1,
    sport: '',
  });
  const {
    control,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm<ProgramForm>({
    defaultValues: props.program
      ? {
          name: props.program.name,
          client: props.program.client?.map(client => client.id),
          viewers: props.program.viewers?.map(viewer => viewer.id),
          quadrennial: props.program.quadrennial,
          macro: props.program.macro,
          meso: props.program.meso,
          start: new Date(props.program.start),
        }
      : {
          name: '',
          client: [1],
          viewers: [],
          quadrennial: '',
          macro: '',
          meso: '',
          start: new Date(),
        },
  });

  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement>(null)

	const submit = () => {
		if (formRef.current) {
			formRef.current.dispatchEvent(
				new Event('submit', { cancelable: true, bubbles: true })
			)
		}
	}
  const onSubmit: SubmitHandler<ProgramForm> = data => {
    if (!aiForm) {
      dispatch(
        programActions.createProgram({
          ...data,
          creator: user!.id,
          program_config: new ProgramConfigClass({
            no_weeks: 1,
            plan: [
              {
                0: [],
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
                6: [],
              },
            ],
          }),
        }),
      );

    } else {
      dispatch(
        programActions.generateProgram({
          ...data,
          creator: user!.id,
          program_config: new ProgramConfigClass({
            no_weeks: 1,
            plan: [
              {
                0: [],
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
                6: [],
              },
            ],
          }),
          prompt: {
            ...promptQuestions,
          },
        }),
      );
    }
    setSubmitting(true)
  };

  function onCancel() {
    dispatch(appActions.closeDialogs());
  }

  function transitionForm() {
    setAiForm(!aiForm);
  }
  
  useEffect(() => {
    if(!loading && loaded && submitting) {
        setSubmitting(false)
        dispatch(appActions.closeDialogs());
    }
  },
  [loaded, loading])


  let clientList: Option[] = [];
  if (clients !== null) {
    clientList = clients?.map((client, i) => {
      return {
        value: client.id,
        label: client.first_name ? `${client.first_name} ${client.last_name}` : client.username,
      };
    });
  }
  clientList.push({ value: user!.id, label: 'Myself' });

  return (
    <div className="program-form-cont" >
      {loading ? (
        <div style={{height: '200px', width: '100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress />
        </div>
      ) : (
        <div className="form-parent" style={transitionStyle1(aiForm)}>
          <form ref={formRef} className="program-form" id="program-info-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="program-form-controls">
              <div style={{ gridRow: '1/2', gridColumn: '1/3' }}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="filled"
                      fullWidth
                      title="Program Name"
                      label="Program Name"
                    />
                  )}
                />
              </div>

              <div style={{ gridRow: '2/3', gridColumn: '1/2' }}>
                <Controller
                  control={control}
                  name="client"
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel variant="filled">Clients</InputLabel>
                      <Select
                        {...field}
                        multiple
                        required
                        variant="filled"
                        title="Program Client"
                        onChange={e => setValue('client', e.target.value as number[])}
                        error={Boolean(errors?.viewers)}
                      >
                        {clientList.map(client => (
                          <MenuItem key={client.value} value={client.value}>
                            {client.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>

              <div style={{ gridRow: '2/3', gridColumn: '2/3' }}>
                <Controller
                  control={control}
                  name="start"
                  render={({ field }) => (
                    <DesktopDatePicker
                      {...field}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label="Start"
                          fullWidth
                          variant="filled"
                          title="Program Start Date"
                        />
                      )}
                    />
                  )}
                />
              </div>

              <div style={{ gridRow: '3/4', gridColumn: '1/2' }}>
                <Controller
                  control={control}
                  name="viewers"
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel variant="filled">Viewers</InputLabel>
                      <Select
                        multiple
                        variant="filled"
                        title="Program Viewers"
                        {...field}
                        onChange={e => setValue('viewers', e.target.value as number[])}
                        error={Boolean(errors?.viewers)}
                      >
                        {clientList.map(client => (
                          <MenuItem key={client.value} value={client.value}>
                            {client.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>

              <div style={{ gridRow: '3/4', gridColumn: '2/3' }}>
                <Controller
                  control={control}
                  name="quadrennial"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="filled"
                      fullWidth
                      label="Quadrennial Cycle"
                      title="Program Quadrennial Cycle"
                    />
                  )}
                />
              </div>

              <div style={{ gridRow: '4/5', gridColumn: '1/2' }}>
                <Controller
                  control={control}
                  name="macro"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      title="Program Macro Cycle"
                      label="Macro Cycle"
                    />
                  )}
                />
              </div>

              <div style={{ gridRow: '4/5', gridColumn: '2/3' }}>
                <Controller
                  control={control}
                  name="meso"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      title="Program meso-cycle"
                      label="Meso Cycle"
                    />
                  )}
                />
              </div>
            </div>

            <div className="form-buttons">
              <Button onClick={() => onCancel()} variant="outlined" color="info">
                Cancel
              </Button>
              {user?.email === 'nick@clevercoachtech.com' && (
                  <Button variant="outlined" onClick={transitionForm}>
                    Generate Program
                  </Button>
              )}

              <Button form="program-info-form" type="submit" variant="contained" color="primary">
                Blank Program
              </Button>
            </div>
          </form>
          <div className="program-form" id="ai-info-form">
            <div className="program-form-controls">
              <div style={{ gridRow: '1/4', gridColumn: '1/3' }}>
                <TextField
                  type="text"
                  variant="filled"
                  minRows={6}
                  label={<Tooltip 
                      title={<React.Fragment>
                        <Typography variant="button" fontSize={10} color="inherit">Pro Tip</Typography>
                        <p>
                        For best results, include more details.
                        </p>
                      </React.Fragment>}
                      >
                  <span>Program Instructions
                    <InfoOutlinedIcon fontSize='small' sx={{marginLeft: '6px'}} /></span>
                    
                </Tooltip>}
                  placeholder='Build a strength program...'
                  fullWidth
                  multiline
                  value={promptQuestions.instructions}
                  onChange={event =>
                    setPromptQuestions({ ...promptQuestions, instructions: event.target.value })
                  }
                />
              </div>
              <div style={{ gridRow: '4/5', gridColumn: '1/2' }}>
              <FormControl fullWidth>
                  <InputLabel variant="filled">No. of Weeks</InputLabel>
                  <Select
                    value={promptQuestions.no_weeks}
                    variant="filled"
                    fullWidth
                    label="No. of Weeks"
                    onChange={event =>
                      setPromptQuestions({
                        ...promptQuestions,
                        no_weeks: event.target.value as number,
                      })
                    }
                  >
                    {no_weeks.map(week => (
                      <MenuItem key={'week' + week} value={week}>
                        {String(week)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* number of weeks maximum of 6 single select input */}
              <div style={{ gridRow: '4/5', gridColumn: '2/3' }}>
              <FormControl fullWidth>
                  <InputLabel variant="filled">Frequency</InputLabel>
                  <Select
                    value={promptQuestions.frequency}
                    variant="filled"
                    fullWidth
                    label="Frequency"
                    onChange={event =>
                      setPromptQuestions({
                        ...promptQuestions,
                        frequency: event.target.value as number,
                      })
                    }
                  >
                    {frequency.map(freq => (
                      <MenuItem key={'frequency' + freq} value={freq}>
                        {String(freq)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={{ gridRow: '4/5', gridColumn: '2/3' }}>
                
              </div>
            </div>
            <div className="form-buttons">
              <Button onClick={() => transitionForm()} variant="outlined" color="primary">
                Program Info
              </Button>
              <Button
                form="program-info-form"
                onClick={submit}
                variant="contained"
                color="primary"
              >
                Generate program
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateProgramForm;

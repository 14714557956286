import { ListUserInterface } from './userTypes';

export enum EventType {
  ResistanceTraining,
  Conditioning,
  PersonalTraining,
  GroupTraining,
  Consult,
  Assessment,
  Meeting,
  Education,
}

export function event_typeIcon(type: EventType) {
  switch (type) {
    case EventType.ResistanceTraining:
      return 'fitness_center';
    case EventType.Conditioning:
      return 'monitor_heart';
    case EventType.PersonalTraining:
      return 'person_outline';
    case EventType.GroupTraining:
      return 'groups';
    case EventType.Consult:
      return 'assignment_ind';
    case EventType.Assessment:
      return 'insert_chart';
    case EventType.Meeting:
      return 'schedule';
    case EventType.Education:
      return 'school';
  }
}
export function event_typeName(type: EventType) {
  switch (type) {
    case EventType.ResistanceTraining:
      return 'Resistance Training';
    case EventType.Conditioning:
      return 'Conditioning';
    case EventType.PersonalTraining:
      return 'Personal Training';
    case EventType.GroupTraining:
      return 'Group Training';
    case EventType.Consult:
      return 'Consultation';
    case EventType.Assessment:
      return 'Assessment';
    case EventType.Meeting:
      return 'Meeting';
    case EventType.Education:
      return 'Education & Development';
  }
}

export class EventBase {
  public id?: number;
  public event_type: EventType = EventType.ResistanceTraining;
  public title: string = '';
  public creator: number | null = null;
  public organiser: number | null = null;
  public backgroundColor: string = '#ba68c8';
  public start: string = new Date().toISOString();
  public end: string = new Date().toISOString();
  public timezone: string = 'Australia/Sydney';
  public url?: string = '';
  constructor(partial?: Partial<EventBase>) {
    Object.assign(this, partial);
  }
}
export class EventDetails extends EventBase {
  public is_series: boolean = false;
  public guests: ListUserInterface[] | null = [];
  public location: string | null = '';
  public description: string | null = '';
  public linked_session: number | null = null;

  constructor(partial?: Partial<EventDetails>) {
    super();
    Object.assign(this, partial);
  }
}
export enum RepeatInterval {
  DAY = 1,
  WEEK = 2,
  FORTNIGHT = 3,
  MONTH = 4,
  YEAR = 5,
}
export class EventSeries {
  public series_name: string = '';
  public repeat: number = 1;
  public repeat_interval: RepeatInterval = RepeatInterval.WEEK;
  public series_events: number[] = [];
  public linked_item: string | null = null;
  constructor(partial?: Partial<EventSeries>) {
    Object.assign(this, partial);
  }
}

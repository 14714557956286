import moment from "moment";
import {
  CooldownInterface,
  PostWorkoutInterface,
  SessionDetailInterface,
  SessionQuestion,
  User,
  WarmupInterface,
  WorkoutDetailInterface,
  WorkoutInterface,
} from '../../tsUtils';
import * as paths from './paths';
import { getAuthHeader } from './paths';
import { WorkoutPromptQuestions } from "../programs/dialogs/WorkoutDialog";

export async function getWorkout(id: number): Promise<WorkoutDetailInterface[]> {
  let endpoint = `${paths.COACHWORKOUT}/${id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function getWorkouts(query: string): Promise<WorkoutDetailInterface[]> {
  let endpoint = `${paths.COACHWORKOUT}${query}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function createWorkout(
  workout: PostWorkoutInterface,
  questions: SessionQuestion[],
  config: {
    day: Day;
    week: number;
    date_time: string;
  },
): Promise<WorkoutDetailInterface> {
  let endpoint = `${paths.COACHWORKOUT}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify({ workout, questions, config: { ...config } }),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function generateWorkout(
  workout: PostWorkoutInterface,
  questions: SessionQuestion[],
  config: {
    day: Day;
    week: number;
    date_time: string;
  },
  prompt: WorkoutPromptQuestions,
): Promise<WorkoutDetailInterface> {
  const endpoint = paths.GENERATEWORKOUT;
  const headers = getAuthHeader();

  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify({ workout, questions, config: { ...config }, prompt }),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function repeatWorkout(data: {
  day: Day;
  week: number;
  program: number;
  workout: number;
  date_time: string;
}): Promise<SessionDetailInterface> {
  let endpoint = `${paths.COACHWORKOUT}/${data.workout}/repeat`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function copyWorkout(workout_id: number): Promise<WorkoutDetailInterface> {
  let endpoint = `${paths.COACHWORKOUT}/${workout_id}/copy`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function updateWorkout(
  workout: WorkoutInterface,
  questions: SessionQuestion[],
): Promise<WorkoutDetailInterface> {
  let endpoint = `${paths.COACHWORKOUT}/${workout.id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify({ workout, questions }),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function renameWorkout(workout_id: number, name: string): Promise<WorkoutInterface> {
  let endpoint = `${paths.COACHWORKOUT}/${workout_id}/rename`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(name),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function deleteWorkout(id: number): Promise<string> {
  let endpoint = `${paths.COACHWORKOUT}/${id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: "DELETE",
    headers,
  }).then(
    (response) => {
      if (response.ok) {
        return response.json();
      }
    },
    (error) => {
      return { error };
    }
  );
}

export async function getWarmups(): Promise<Response> {
  let endpoint = paths.COACHWARMUP;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  })
}

export async function getCooldowns(): Promise<Response> {
  let endpoint = paths.COACHCOOLDOWN;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  })
}

export async function createWarmup(warmup: WarmupInterface, workout_id?: number): Promise<Response> {
  let endpoint = paths.COACHWARMUP;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify({warmup, workout_id}),
  })
}

export async function createCooldown(cooldown: CooldownInterface, workout_id?: number): Promise<Response> {
  let endpoint = paths.COACHCOOLDOWN;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify({cooldown, workout_id}),
  })
}

export async function addWarmupToWorkout(workout_id: number, warmup_id: number): Promise<Response> {
  let endpoint = `${paths.COACHWARMUP}/${warmup_id}/add`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify(workout_id),
  })
}

export async function addCooldownToWorkout(workout_id: number, cooldown_id: number): Promise<Response> {
  let endpoint = `${paths.COACHCOOLDOWN}/${cooldown_id}/add`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify(workout_id),
  })
}

export async function addWarmupsToWorkout(workout_id: number, warmups: number[]): Promise<Response> {
  let endpoint = `${paths.COACHWORKOUT}/${workout_id}/add_warmups`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify({warmups}),
  })
}

export async function addCooldownsToWorkout(workout_id: number, cooldowns: number[]): Promise<Response> {
  let endpoint = `${paths.COACHWORKOUT}/${workout_id}/add_warmups`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify({cooldowns}),
  })
}

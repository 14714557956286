import { Button, LinearProgress } from '@mui/material';
import * as React from 'react';
import ReactToPrint from 'react-to-print';
import {
  clearSelectedProgram,
  programSlice,
  selectDetailedProgram,
} from '../+store/programs/reducers';
import PrintProgramComponent from './containers/ProgramPrint';
import '../style/Preview/ClientProgram.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useEffect } from 'react';
import { programActions } from '../+store/programs/actionTypes';
import { sessionSlice } from '../+store/sessions/reducers';
import { workoutSlice } from '../+store/workouts/reducers';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export interface IPrintPageProps {
  client: boolean;
}

export default function PrintPage(props: IPrintPageProps) {
  const { programId, workout_id, session_id } = useParams();
  const program = useSelector((state: RootState) =>
    selectDetailedProgram(state, Number(programId)),
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!props.client) {
      dispatch(programActions.getProgramWorkouts(Number(programId)));
    }
    if (props.client) {
      dispatch(programActions.getClientProgramWorkouts(Number(programId)));
    }
    if (workout_id && session_id) {
      dispatch(sessionSlice.actions.setSelectedSessionId(Number(session_id)));
      dispatch(workoutSlice.actions.setSelectedWorkoutId(Number(workout_id)));
    }
  }, []);
  const componentRef = React.useRef<HTMLDivElement>(null);
  function goBack() {
    navigate(-1);
  }
  return (
    <div className="print-page">
      {program ? (
        <>
          <div className="print-nav-stuff">
            <Button onClick={() => goBack()} variant="contained">
              <ArrowBackIcon></ArrowBackIcon>
              Back
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary">
                  Print Program
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div style={{width: '100%', overflowX: 'auto'}}>
          <div className="print-cont">
            <PrintProgramComponent ref={componentRef} program={program} />
          </div>
          </div>
        </>
      ) : (
        <LinearProgress sx={{ width: '100%' }} />
      )}
    </div>
  );
}

import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SessionForm from '../../../coachApp/programs/components/SessionForm';
import { getSelectedWorkout } from '../../../+store/workouts/reducers';
import { selectCurrentSession } from '../../../+store/sessions/reducers';
import { RootState } from '../../../store/store';
import WorkoutFormComponent from '../../../coachApp/programs/components/WorkoutFormComponent';

const wrap: 'wrap' | 'nowrap' = 'wrap';

const ContainerStyle = {
  display: 'flex',
  justifyContent: 'space-evenly',
  margin: '0px 0px 15px 0px',
  padding: '0px 10px',
  alignItems: 'center',
  flexWrap: wrap,
  width: '100%',
};

function ClientWorkout() {
  const workout = useSelector((state: RootState) => getSelectedWorkout(state));
  const session = useSelector((state: RootState) => selectCurrentSession(state));
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (workout && session) {
      setLoading(false);
    }
  }, [workout, session]);

  return (
    <>
      {workout && session ? (
        <div style={ContainerStyle}>
          <WorkoutFormComponent workout={workout} />
          <SessionForm workout_id={workout.id}  session={session} />
          
          <div className="program-footer">
            <div style={{ color: 'green' }}></div>
          </div>
        </div>
      ) : (
        <LinearProgress sx={{ width: '100%' }} />
      )}
    </>
  );
}

export default ClientWorkout;

import React, {useEffect, useState} from 'react'

export const useConfigDateTime = (startTime: Date) => {

  const [date_time, setDate] = useState<Date>(startTime)

  const [{startDate, day, week}, setVariables] = useState<{startDate: Date, day: number, week: number}>({startDate: new Date(), day: 0, week: 0})
  
  const setDateTime = (time: Date) => {
    const weekVal = week * 7;
    let newDate = new Date(time)
    newDate.setDate(new Date(startDate).getDate() + (day - new Date(startDate).getDay() + weekVal));
    setDate(newDate);
  };

  const setInitialDateTime = (start: Date, dayNo: number, weekNo: number, time: Date) => {
    setVariables({startDate: start, day: dayNo, week: weekNo});
  }

  useEffect(() => {
    setDateTime(startTime);
  }, [startDate, day, week]);

  return {date_time, setDateTime, setInitialDateTime}
}


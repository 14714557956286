import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../ClientStyle/ClientCard.scss';
import {openPreviousInputs} from '../../../store/app/actions';
import { Button, CircularProgress, Typography } from '@mui/material';
import ClientCardInputs from './ClientCardInputs';
import { RootState } from '../../../store/store';
import { exerciseCardSelectors, selectClientInputs, selectPreviousClientInputs } from '../../../+store/exerciseCards/reducers';
import RestTimerDialog from '../dialogs/RestTimer';
import { useCardTotals } from '../../../common/hooks/useCardTotals';
import { Definition } from '../../../tsUtils';

interface Props {
  index: number;
  id: number;
  userId: number;
  inputOptions: { [key: string]: Definition };
}

function ClientExerciseCard(props: Props) {
  const cardDetails = useSelector((state: RootState) =>
    exerciseCardSelectors.selectById(state, props.id),
  );
  const client_inputs = useSelector((state: RootState) =>
    selectClientInputs(state, props.id),
  );
  const dispatch = useDispatch();
  const [totals, updateTotals, totalsLoading] = useCardTotals();
  const previousInputs = useSelector((state: RootState) =>
    selectPreviousClientInputs(state, props.index),
  );

  function showPrevious() {
    dispatch(openPreviousInputs(props.userId, props.index));
  }

  useEffect(() => {
    if (cardDetails && client_inputs) {
      updateTotals(cardDetails, client_inputs.inputs);
    }
  }, [cardDetails, client_inputs]);

  return cardDetails && props.inputOptions[1] ? (
    <div className="client-card">
      <div className="cardHead">
        <div className="head_item">
          <span style={{lineHeight:"42px"}} className="cardNo">{cardDetails.config.card_no}</span>
        </div>
        <div className="head_item">
            <Typography variant="button" sx={{ lineHeight: 1, fontSize: '.95em' }} component="span">
              {cardDetails.exercise?.name}
            </Typography>
        </div>
      </div>
      <div className="variation-cont">
        <div className="variation">
          <Typography variant="button"component="span">
            Variation 1:
          </Typography>
          <Typography variant="body1" >
            {cardDetails.config.variation_1 !== '' ? cardDetails.config.variation_1 : '-'}
          </Typography>
        </div>
        <div className="variation">
          <Typography variant="button" component="span">
            Variation 2:
          </Typography>
          <Typography variant="body1" >
            {cardDetails.config.variation_2 !== '' ? cardDetails.config.variation_2 : '-'}
          </Typography>
        </div>
        <div className="variation">
          <Typography variant="button" component="span">
            Equipment:
          </Typography>
          <Typography variant="body1" >
            {cardDetails.config.equipment !== '' ? cardDetails.config.equipment : '-'}
          </Typography>
        </div>

        <div className="variation">
          <Typography variant="button" component="span">
            Adjusted Max:
          </Typography>
          <Typography variant="body1" >
            {cardDetails.config.adjust_max}
          </Typography>
        </div>

        <div className="variation">
          <Typography variant="button" component="span">
            Tempo:
          </Typography>
          <Typography variant="body1" >
            {cardDetails.config.tempo !== '' ? cardDetails.config.tempo : '-'}
          </Typography>
        </div>

        <div className="variation">
          <Typography variant="button" component="span">
            Rest:
          </Typography>
          {cardDetails.config.rest !== '' ? 
            <RestTimerDialog rest={cardDetails.config.rest} /> : 
            <Typography variant="body1" >
            -
            </Typography>
          }
        </div>
      </div>
      {
        previousInputs && (
      <div >
        <Button variant="outlined" color="primary" onClick={showPrevious} fullWidth size='small' >
          Previous Inputs
        </Button>
      </div>
        )
      }
      <div>
        {client_inputs ? (
          <ClientCardInputs
          cardIndex={props.index}
          inputs={client_inputs}
          prescribed={client_inputs.prescribed}
          inputOptions={props.inputOptions}
        />
        ) : (
          <div>No client inputs</div>
        )}
      </div>
      <div className="variation-cont">
        { 
        !totalsLoading ?
        Object.entries(totals).map(([key, value]: [string, string], index) => {
          if (value && !value.includes('NaN')) {
            return (
              <div key={cardDetails.id + key} className="variation">
                <Typography variant="button" component="span">
                  {key}:
                </Typography>
                <Typography variant="body1">
                  {value}
                </Typography>
              </div>
            );
            }
        }) :
        (
          <CircularProgress />
        )
        }
      </div>
    </div>
  ) : (
    <div className="card">not found</div>
  );
}

export default ClientExerciseCard;

import React from 'react';
import { Select } from '@mui/material';

import { styled } from '@mui/system';
export interface StyledSelectProps {
  height?: string;
  backgroundcolor?: string;
}
export const StyledSelect = styled(Select)((props: StyledSelectProps) =>`
  & .MuiOutlinedInput-notchedOutline {
    border: none;
    height: ${props.height || '20px'};
    line-height: ${props.height || '20px'};
    min-height: 0px;
    text-align: center;
    width: 100%;
  }
  & .MuiSelect-select {
    height: ${props.height || '20px'};
    min-height: 0px !important;
    border: none;
    line-height: ${props.height || '20px'};
    width: 100%;
    background: ${props.backgroundcolor || '#eee'};
    border-radius: 4px;
    text-align: center;
  }
  & .MuiOutlinedInput-input {
    min-height: 0px;
  }
  & .MuiSelect-nativeInput {
    height: calc(${props.height || '20px'} + 12px);
    line-height: calc(${props.height || '20px'} + 12px);
  }
  .MuiSelect-nativeInput label {
    height: calc(${props.height || '20px'} + 12px);
    line-height: calc(${props.height || '20px'} + 12px);
  }
  & .MuiInputBase-input {
    min-height: 0px;
    padding: 6px 8px;
  }
  & .MuiSelect-outlined {
    border: none;
    min-height: 0px;
    height: ${props.height || '20px'};
    line-height: ${props.height || '20px'};
    width: 100%;
    text-align: center;
  }
  & .MuiSvgIcon-root {
    right: 1px;
  }
`);

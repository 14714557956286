import React, { useEffect} from 'react';
import { Definition, ExerciseCardClass, input_type, InputGroup } from '../../../tsUtils';
import { MenuItem, Select, Button, Avatar, Icon, IconButton } from '@mui/material';
import '../../../style/Coach/Sets.scss';
import * as defaults from '../../../tsUtils/defaults';
import store from '../../../store/store';
import { StyledInput } from '../../../common/StyledInput';
import { StyledSelect } from '../../../common/StyledSelect';
import { selectinput_types } from '../../../+store/definitions/inputs/reducers';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { Add } from '@mui/icons-material';

interface Props {
  cardIndex: number;
  inputOptions: Definition[];
  inputs: InputGroup;
  client?: number;
  onInputChange: (inputs: InputGroup) => void;
  disableKeys?: boolean;
}

const styles = {
  inputsContainer: (numberInputs: number) => { return {
    margin: '0px 5px' ,
    display: 'grid',
    gridTemplateColumns: `30px repeat(${numberInputs}, 1fr) 34px`,
    gap: '2px',
  }},
  cardInputs: {
    display: 'flex',
    flexDirection: 'column',
  },
  divItem: {
    display: 'grid',
    gridTemplateRows: 'repeat(auto-fill, 32px)',
    gap: '2px',
  },
  avatar: {
    width: '24px',
    height: '24px',
    margin: '4px 2px',
    fontSize: 'small',
    backgroundColor: '#15998E',
  },
  select: {
    fontSize: 'small',
  },
  deleteIcon: {
    cursor: 'pointer',
    height: '32px',
  },
  button: {
    height: '32px',
  },
  spacer: {
    height: '25px',
    textAlign: 'center',
  },
  deleteSpacer: {
    height: '32px',
    textAlign: 'center',
  },
} ;

function CardInputs(props: Props) {
  const isMobile = useSelector((state: any) => state.app.isMobile);

  const options = props.inputOptions.map(opt => (
    <MenuItem key={opt.id + "options"} sx={{ fontSize: 'small', }} title={opt.name} value={opt.id}>
     <span style={{textTransform: 'uppercase'}}>{ isMobile? opt.initials: opt.name }</span> 
    </MenuItem>
  ));

  const addSet = () => {
    const newInputs = defaults.newSet(props.inputs);
    props.onInputChange(newInputs);
  };

  const removeSet = (index: number) => {
    const newInputs = (props.inputs as InputGroup).reduce((acc, {key, value}, arr_index) => {
      acc.push({key, value: value.filter((_, j) => j !== index)});
      return acc;
    }, [] as InputGroup);
    props.onInputChange(newInputs);
  };

  const updateKey = (index: number, k: number) => {
    let newInputs: InputGroup = [...props.inputs]
    if (k === 0) {
      newInputs = (props.inputs as InputGroup).reduce((acc, {key, value}, arr_index) => {
        if (arr_index !== index) {
          acc.push({key, value}) ;
        }
        return acc;
      }, [] as InputGroup);
      props.onInputChange(newInputs);
      return;
    }
    newInputs = (props.inputs as InputGroup).reduce((acc, {key, value}, i) => {
      
      if (i === index) {
        acc.push({key: k, value: value});
    } else {
        acc.push({key, value});
      }
      return acc;
    }, [] as InputGroup);
    props.onInputChange(newInputs);
  };

  const addInputGroup = () => {
    const keys = props.inputs.map(({key}) => key);
    const unusedKey = props.inputOptions.find(opt => keys.findIndex((k) => k === opt.id!) === -1);
    if (!unusedKey) return;
    const newInputs = [ ...props.inputs[0].value.map(() => '')];
    const newInputGroup = { key: unusedKey.id!, value: newInputs };
    props.onInputChange([...props.inputs, newInputGroup]);
  }

  const handleInputChange = (inputIndex: number, setIndex: number, e: React.ChangeEvent<HTMLInputElement>) => {
    // Create a deep copy of the valueGroup
    const newInputs = props.inputs.map(input => ({
      ...input,
      value: [...input.value]
    }));
  
    // Ensure newInputs[inputIndex] and newInputs[inputIndex].value are defined
    if (newInputs[inputIndex] && newInputs[inputIndex].value) {
      newInputs[inputIndex].value[setIndex] = e.target.value;
      props.onInputChange(newInputs);
    }
  };

  const getInput = (
    key: number,
    client: boolean,
    inputIndex: number,
    setIndex: number,
  ) => {
    let ratingOpt: React.ReactElement[] = [];
    for (let i = 1; i <= 10; i++) {
      ratingOpt.push(
        <MenuItem className="setInput" key={i} value={i.toString()}>
          {i}
        </MenuItem>,
      );
    }
    let def;
    if (key) {
      def = props.inputOptions.find(x => x.id! === key);
    }
    if (client) {
      return (
        <StyledInput
          variant="outlined"
          size="small"
          key={key + inputIndex + setIndex + "input"}
          fullWidth
          disabled={client}
          value={props.inputs[inputIndex].value[setIndex]}
        />
      );
    }
    switch (def?.input_type) {
      case input_type.Select:
        return (
          <StyledSelect
            value={props.inputs[inputIndex].value[setIndex]}
            onChange={e => {
              handleInputChange(inputIndex, setIndex, e as React.ChangeEvent<HTMLInputElement>)
            }
            }
            size="small"
            key={key + inputIndex + setIndex + "input"}
            variant="outlined"
            sx={{ minHeight: '0px' }}
            fullWidth
            disabled={client}
          >
            {ratingOpt}
          </StyledSelect>
        )
      default:
        return (
          <StyledInput
            variant="outlined"
            size="small"
            fullWidth
            key={key + inputIndex + setIndex + "input"}
            disabled={client}
            value={props.inputs[inputIndex].value[setIndex]}
            onChange={e => {
              handleInputChange(inputIndex, setIndex, e as React.ChangeEvent<HTMLInputElement>);
            }
            }
          />
          )
    }
  };

  return (
    <div style={styles.cardInputs as React.CSSProperties} >
      <div style={styles.inputsContainer(props.inputs.length)}>
        <div style={styles.divItem as React.CSSProperties}>
          <div>No.</div>
          {props.inputs[0]?.value.map((_, index) => (
            <Avatar key={index + "avatar"} sx={styles.avatar}>
              {index + 1}
            </Avatar>
          ))}
        </div>

        {props.inputs.map(({key, value}, index) => {
          const currentKey = Number(key);
          return (
            <div key={key + index + "inputs"} style={styles.divItem as React.CSSProperties}>
              <Select
                className="input_type"
                MenuProps={{
                  onClick: event => event.stopPropagation(),
                  sx: { maxHeight: 250 },
                }}
                disabled={props.disableKeys}
                onChange={e => updateKey(index, Number(e.target.value))}
                size="small"
                sx={styles.select}
                variant="standard"
                defaultValue={6}
                value={currentKey}
              >
                {options}
                <MenuItem key={-1} sx={styles.select} title="-" value="">
                  -
                </MenuItem>
              </Select>
              {value?.map((v: any, i: number) => (
                getInput(key, props.client !== 0, index, i)
              ))}
            </div>
          );
        })}
        <div style={styles.divItem as React.CSSProperties}>
          <div style={styles.spacer as React.CSSProperties}>
            {!props.disableKeys &&
              <Add sx={{ cursor: 'pointer' }} color="primary" onClick={addInputGroup} />
            }
          </div>
          {props.inputs[0]?.value.map((_, index) => (
            <div style={styles.deleteSpacer as React.CSSProperties} key={index + "delete"}>
              <DeleteIcon
                color="error"
                fontSize="medium"
                onClick={() => removeSet(index)}
                sx={styles.deleteIcon}
              />
            </div>
          ))}
        </div>
      </div>
      <Button fullWidth size="medium" variant='text' sx={styles.button} onClick={addSet}>
        + Set
      </Button>
    </div>
  );
}

export default CardInputs;
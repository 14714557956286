import { call, put, takeLatest, SagaReturnType } from 'redux-saga/effects';
import * as API from '../../coachApp/services/clientAPI';
// import { REFRESH_TOKEN_REQUEST, signInFail, signInSuccess, SIGN_IN_REQUEST, SIGN_IN_SUCCESS } from "../../.";
import * as actionTypes from './actionTypes';
import {
  clientsFail,
  clientsLoaded,
  clientsLoading,
  getClientsSuccess,
  createClientSuccess,
  deleteClientSuccess,
  updateClientActive,
  getSelectedClient,
} from './reducers';
import * as types from './types';
import { SuccessResponse } from '../../tsUtils/responseType';
import { saved} from '../../store/app/actions';

export function* getClients(): any {
  try {
    yield put(clientsLoading());
    const response: SagaReturnType<typeof API.getClients> = yield call(API.getClients);
    yield put(getClientsSuccess(response));
    yield put(clientsLoaded());
  } catch (error) {
    yield put(clientsFail(error));
  }
}

export function* getClientSaga({ id }: types.GetClientRequest) {
  try {
    yield put(clientsLoading());
    const response: SagaReturnType<typeof API.getClient> = yield call(API.getClient, id);
    if (response.ok) {
      const data: SuccessResponse<any> = yield response.json();
      yield put(getSelectedClient(data.data));
      yield put(clientsLoaded());
    }
  } catch (error: any) {
    yield put(clientsFail(error));
  }
}

export function* createClientSaga({ client }: types.CreateClientRequest) {
  try {
    yield put(clientsLoading());
    const response: SagaReturnType<typeof API.createClient> = yield call(API.createClient, client);
    yield put(createClientSuccess(response));
    yield put(clientsLoaded());
  } catch (error: any) {
    yield put(clientsFail(error));
  }
}

export function* deleteClientSaga({ id }: types.DeleteClientRequest) {
  try {
    yield put(clientsLoading());
    yield call(API.deleteClient, id);
    yield put(deleteClientSuccess(id));
    yield put(clientsLoaded());
  } catch (error: any) {
    yield put(clientsFail(error));
  }
}
export function* updateClientSaga({ client, clientId }: types.UpdateClientRequest) {
  try {
    yield put(clientsLoading());
    const response: SagaReturnType<typeof API.updateClient> = yield call(API.updateClient, client, clientId);
    yield put(createClientSuccess(response));
    yield put(clientsLoaded());
  } catch (error: any) {
    yield put(clientsFail(error));
  }
}

export function* inviteClientSaga({ client_id }: types.InviteClientRequest) {
  try {
    const response: SagaReturnType<typeof API.inviteClient> = yield call(API.inviteClient, client_id);
    if (response.ok) {
      const data: SuccessResponse<{message: string} > = yield response.json();
      yield put(updateClientActive(client_id));
      yield put(saved(data.message));
    }
  } catch (error: any) {
    yield put(clientsFail(error));
  }
}

export function* clientSaga() {
  yield takeLatest(actionTypes.GET_CLIENTS, getClients);
  yield takeLatest(actionTypes.CREATE_CLIENT_REQUEST, createClientSaga);
  yield takeLatest(actionTypes.DELETE_CLIENT_REQUEST, deleteClientSaga);
  yield takeLatest(actionTypes.UPDATE_CLIENT_REQUEST, updateClientSaga);
  yield takeLatest(actionTypes.INVITE_CLIENT_REQUEST, inviteClientSaga);
  yield takeLatest(actionTypes.GET_CLIENT, getClientSaga);
}

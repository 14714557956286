import React from 'react';
import { Definition, InputGroup } from '../tsUtils';

import { Grid, Avatar, Typography, debounce } from '@mui/material';

import '../style/Preview/ClientSets.scss';

interface Props {
  inputs: InputGroup | null;
  cardId: string;
  inputOptions: { [key: string]: Definition };
}

function PreviewCardInputs(props: Props) {

  return (
    <div className="cardInputs">
      <div style={{ 
        width: '100%',
        margin: '0px 5px' ,
          display: 'grid',
          gridTemplateColumns: `30px repeat(${props.inputs?.length}, 1fr)`,
          gap: '2px',
          justifyContent: 'center', }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography fontSize="x-small" variant="button">
            No.
          </Typography>
        </div>
        {props.inputs && 
          props.inputs.map(({key, value}) => {
            return (
              <div
                key={key}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="button" fontSize="x-small">
                  { props.inputs?.length && props.inputs?.length < 4 ? props.inputOptions[key].name : props.inputOptions[key].initials }
                </Typography>
                
              </div>
            )
          })
        }
      </div>
      <div
        style={{
          margin: '0px 5px' ,
          display: 'grid',
          gridTemplateColumns: `30px repeat(${props.inputs?.length}, 1fr)`,
          gap: '2px',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.inputs && 
            props.inputs[0].value.map((value, index) => 
            <Avatar 
              key={index + "avatar"} 
              sx={{    
              width: '24px',
              height: '24px',
              margin: '4px 2px',
              fontSize: 'small',
              backgroundColor: '#15998E',
              }}>
            {index + 1}
            </Avatar>
            )
          }
        </div>
        {props.inputs && 
          props.inputs.map(({key, value}) => {
            return (
              <div>
                {value.map((input: string) => {
                  return (
                  <div style={{height: '30px', display: 'flex', margin: '2px', borderRadius: "5px", border: '1px solid #ccc'}} >
                    <div style={{ lineHeight: '26px', textAlign: 'center', borderRight: '1px solid #ccc', width: '50%' }}>
                      {input}
                    </div>
                    <div style={{ lineHeight: '26px',textAlign: 'center' }}></div>
                  </div>
                  );
                })}
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

export default PreviewCardInputs;

// Previous inputs using ClientInputs.tsx
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { selectPreviousClientInputs } from "../../../+store/exerciseCards/reducers";
import { RootState } from "../../../store/store";
import { selectinput_types, selectInputTypesAsObject } from "../../../+store/definitions/inputs/reducers";
import ClientCardInputs from "../components/ClientCardInputs";

interface Props {
    userId: number;
    index: number;
}
const styles = {
    card: {
        width: '100%',
        maxWidth: '100%',
    }
}

export default function PreviousInputs(props: Props) {

    const previousInputs = useSelector((state: RootState) => selectPreviousClientInputs(state, props.index));
    const inputTypes = useSelector((state: RootState) => selectInputTypesAsObject(state));

    return (
        
        <div style={styles.card}>
            {previousInputs && (
             <ClientCardInputs
                prescribed={previousInputs.inputs}
                inputs={previousInputs}
                cardIndex={props.index}
                inputOptions={inputTypes}
            />
            )}
                    
        </div>
    )
}


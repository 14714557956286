import React from 'react';

import '../style/Preview/ClientCard.scss';

import { Definition, ExerciseBaseInterface, ExerciseCardClass } from '../tsUtils';

import { Box, Grid, TextField, Typography } from '@mui/material';

import PreviewCardInputs from './PreviewCardInputs';
import store from '../store/store';

interface Props {
  card: ExerciseCardClass;
  inputOptions: { [key: string]: Definition };
}

function PreviewExerciseCard(props: Props) {
  const getExerciseName = (exercise: ExerciseBaseInterface | number) => {
    if (typeof exercise !== 'number') {
      return exercise.name;
    } else {
      return store.getState().definitions.exercises?.entities[exercise]?.name;
    }
  };

  return (
    <div className="print-card">
      <div className="cardHead">
        <div className="head_item">
          <span className="card-no">{props.card.config.card_no}</span>
        </div>

        <div className="head_item">
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Typography variant="h6" sx={{ lineHeight: 1 }} component="span">
              {props.card.exercise ? getExerciseName(props.card.exercise) : props.card.user_exercise?.name ?? 'No exercise set'}
            </Typography>
          </Box>
        </div>
        <div className="head_item"></div>
      </div>

      <div className="variation-cont">
        <div className="variation">
          <Typography variant="button" fontSize="x-small" component="span">
            Variation 1
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: '90%',
              border: '1px solid #ccc',
              borderRadius: '5px',
              background: ' #eee',
              margin: '0px auto',
            }}
            fontSize="x-small"
            component="span"
          >
            {props.card.config.variation_1 ? props.card.config.variation_1 : '-'}
          </Typography>
        </div>
        <div className="variation">
          <Typography variant="button" fontSize="x-small" component="span">
            Variation 2
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: '90%',
              border: '1px solid #ccc',
              borderRadius: '5px',
              background: ' #eee',
              margin: '0px auto',
            }}
            fontSize="x-small"
            component="span"
          >
            {props.card.config.variation_2 ? props.card.config.variation_2 : '-'}
          </Typography>
        </div>
        <div className="variation">
          <Typography variant="button" fontSize="x-small" component="span">
            Equipment
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: '90%',
              border: '1px solid #ccc',
              borderRadius: '5px',
              background: ' #eee',
              margin: '0px auto',
            }}
            fontSize="x-small"
            component="span"
          >
            {props.card.config.equipment ? props.card.config.equipment : '-'}
          </Typography>
        </div>

        <div className="variation">
          <Typography variant="button" fontSize="x-small" component="span">
            Adjusted Max
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: '90%',
              border: '1px solid #ccc',
              borderRadius: '5px',
              background: ' #eee',
              margin: '0px auto',
            }}
            fontSize="x-small"
            component="span"
          >
            {props.card.config.adjust_max}
          </Typography>
        </div>

        <div className="variation">
          <Typography variant="button" fontSize="x-small" component="span">
            Tempo
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: '90%',
              border: '1px solid #ccc',
              borderRadius: '5px',
              background: ' #eee',
              margin: '0px auto',
            }}
            fontSize="x-small"
            component="span"
          >
            {props.card.config.tempo ? props.card.config.tempo : '-'}
          </Typography>
        </div>

        <div className="variation">
          <Typography variant="button" fontSize="x-small" component="span">
            Rest
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: '90%',
              border: '1px solid #ccc',
              borderRadius: '5px',
              background: ' #eee',
              margin: '0px auto',
            }}
            fontSize="x-small"
            component="span"
          >
            {props.card.config.rest ? props.card.config.rest : '-'}
          </Typography>
        </div>
      </div>

      <div>
        <PreviewCardInputs
          cardId={props.card.config.card_no}
          inputs={props.card.config.inputs}
          inputOptions={props.inputOptions}
        />
      </div>
      <div
        style={{
          width: 'calc(100% - 10px)',
          height: '35px',
          border: '1px solid #ccc',
          borderRadius: '5px',
          background: '#eee',
          margin: '5px auto',
        }}
      ></div>
    </div>
  );
}

export default PreviewExerciseCard;

import { SessionDetailInterface } from "./sessionTypes";

export enum WorkoutType {
  ResistanceTraining = 0,
  Conditioning = 1,
  Other = 2,
}

export interface WarmupCooldownConfigItem {
  exercise: string; 
  sets: string;
  reps: string;
}

export interface WarmupInterface {
  id?: number;
  name: string;
  category: string;
  config: WarmupCooldownConfigItem[];
  description: string;
  media: string;
}
export interface CooldownInterface {
  id?: number;
  name: string;
  category: string;
  config: WarmupCooldownConfigItem[];
  description: string;
  media: string;
}
export interface PostWorkoutInterface {
  program: number;
  name: string;
  workout_type: WorkoutType;
}
export interface WorkoutBaseInterface {
  program: number;
  name: string;
  order: number;
  warmups: any;
  cooldowns: any;
  modified: Date;
  workout_type: WorkoutType;
  id: number;
}
export interface WorkoutInterface extends WorkoutBaseInterface {
  sessions: { id: number; order: number }[];
}
export class WorkoutClass<WorkoutInterface> {
  constructor(partial: WorkoutInterface) {
    Object.assign(this, partial);
  }
}
export interface WorkoutDetailInterface extends WorkoutBaseInterface {
  sessions: SessionDetailInterface[];
}

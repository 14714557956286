import React, { useCallback, useEffect } from 'react';
import { AccountType, CooldownInterface, WarmupInterface, WorkoutInterface } from '../../../tsUtils';
import { Avatar, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { openAddWarmupDialog, openAddCooldownDialog, openWarmupCooldownDetailsDialog } from '../../../store/app/actions';
import { RootState } from '../../../store/store';

export type WorkoutForm = WorkoutInterface;

type Props = {
  workout: WorkoutInterface;
};

export default function WorkoutFormComponent(props: Props) {
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();

  const handleCreateWarmup = () => {
    dispatch(openAddWarmupDialog({ workout_id: props.workout.id, warmups: props.workout.warmups }));
  };

  const handleCreateCooldown = () => {
    dispatch(openAddCooldownDialog({ workout_id: props.workout.id, cooldowns: props.workout.cooldowns }));
  }

  const handleOpenWarmupCooldownDetails = (item: WarmupInterface | CooldownInterface) => {
    dispatch(openWarmupCooldownDetailsDialog({ item }));
  }

  return (
    <div className="warmup-cooldown">
      <div className="card">
        <Typography fontSize="large" component="span" variant="button">Warmup</Typography>
        {props.workout.warmups.length > 0 ? props.workout.warmups.map((warmup: any, index: number) => (
          <Button
            variant="outlined"
            size="small"
            sx={{marginBottom: '5px'}}
            color="info"
            onClick={() => handleOpenWarmupCooldownDetails(warmup)}
            key={warmup.id}
          >
            <Typography fontSize="medium" component="span" variant="button">{warmup.name}</Typography>
          </Button>
        )) : <Typography fontSize="medium" component="span" variant="button">-</Typography>}
        {user?.account_type !== AccountType.Client && (
          <Button onClick={handleCreateWarmup} color="primary">
            + Add Warmup
          </Button>
        )}
        
      </div>
      <div className="card">
        <Typography fontSize="large" component="span" variant="button">Cooldown</Typography>
        {props.workout.cooldowns.length > 0 ? props.workout.cooldowns.map((cooldown: any, index: number) => (
          <Button 
            variant="outlined" 
            size="small" 
            color="info"
            sx={{marginBottom: '5px'}}
            key={cooldown.id}
            onClick={() => handleOpenWarmupCooldownDetails(cooldown)}
          >
            <Typography fontSize="medium" component="span" variant="button">{cooldown.name}</Typography>
          </Button>
        )) : <Typography fontSize="medium" component="span" variant="button">-</Typography>}
        {user?.account_type !== AccountType.Client && (
          <Button onClick={handleCreateCooldown} color="primary">
            + Add Cooldown
          </Button>
        )}
      </div>
    </div>
  );
}



import { call, put, takeLatest, SagaReturnType } from 'redux-saga/effects';
import { reduceProgram } from '../../store/utility';
import * as programAPI from '../../coachApp/services/programAPI';
import { getWorkoutsSuccess, workoutSlice, workoutsLoaded, workoutsLoading } from '../workouts/reducers';
import { getSessionsSuccess, sessionSlice } from '../sessions/reducers';
import { getClientInputsSuccess, getExerciseCardsSuccess } from '../exerciseCards/reducers';
import { forwardTo } from '../../index';
// import { REFRESH_TOKEN_REQUEST, signInFail, signInSuccess, SIGN_IN_REQUEST, SIGN_IN_SUCCESS } from "../../.";
import { actionTypes } from './actionTypes';
import {
  programsLoading,
  programsLoaded,
  programsFail,
  getProgramsSuccess,
  createProgramSuccess,
  updateProgramSuccess,
  deleteProgramSuccess,
  getProgramSuccess,
  selectProgramDetails,
  addProgramWeekSuccess,
  selectProgramClientDetails,
  selectProgramConfig,
  removeProgramWeekSuccess,
  updateProgramMessage,
  setSelectedById,
  clearSelectedProgram,
  programSlice,
} from './reducers';
import * as types from './types';
import store from '../../store/store';
import * as clientProgramApi from '../../clientApp/services/programAPI';
import { ProgramConfigClass, ProgramConfigSession, WeekConfig } from '../../tsUtils';
import BaseDialog from '../../coachApp/programs/dialogs/BaseDialog';
import { render } from 'react-dom';
import { sessionActions } from '../sessions/actionTypes';
import { updateClientInputs } from '../../clientApp/services/clientInputsApi';
import * as templateApi from '../../coachApp/services/templateAPI';
import { getTemplateSuccess } from '../templates/reducers';

export function* getProgramsSaga({ query }: types.GetPrograms) {
  try {
    if (!query) {
    yield put(programsLoading());
    }
    const response: SagaReturnType<typeof programAPI.getPrograms> = yield programAPI.getPrograms(
      query,
    );
    yield put(getProgramsSuccess(response));
    yield put(programsLoaded());
  } catch (error: any) {
    yield put(programsFail(error));
  }
}
export function* clientGetProgramsSaga({ query }: types.GetPrograms) {
  try {
    yield put(programsLoading());
    const response: SagaReturnType<typeof clientProgramApi.clientGetPrograms> =
      yield clientProgramApi.clientGetPrograms(query);
    yield put(getProgramsSuccess(response));
    yield put(programsLoaded());
  } catch (error: any) {
    yield put(programsFail(error));
  }
}
export function* clientGetProgramSaga({ id }: types.GetClientProgramWorkouts) {
  try {
    yield put(programsLoading());
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof clientProgramApi.getProgram> =
      yield clientProgramApi.getProgram(id);
    const { selectedProgram, workouts, sessions, exercise_cards, client_inputs } = yield reduceProgram(response);
    yield put(getProgramSuccess(selectedProgram));
    yield put(getWorkoutsSuccess(workouts));
    yield put(getSessionsSuccess(sessions));
    yield put(getExerciseCardsSuccess(exercise_cards));
    yield put(getClientInputsSuccess(client_inputs));
    yield put(workoutsLoaded());
    yield put(programsLoaded());
  } catch (error: any) {
    yield put(programsFail(error));
    yield call(forwardTo, '/client/programs');
  }
}
export function* getProgramWorkoutsSaga({ id }: types.GetProgramWorkouts) {
  try {
    yield put(programsLoading());
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof programAPI.getProgram> = yield programAPI.getProgram(id);
    const { selectedProgram, workouts, sessions, exercise_cards, client_inputs } = yield reduceProgram(response);
    yield put(getProgramSuccess(selectedProgram));
    yield put(getWorkoutsSuccess(workouts));
    yield put(getSessionsSuccess(sessions));
    yield put(getExerciseCardsSuccess(exercise_cards));
    yield put(getClientInputsSuccess(client_inputs));
    yield put(workoutsLoaded());
    yield put(programsLoaded());
  } catch (error: any) {
    yield put(programsFail(error));
    yield call(forwardTo, '/coach/programs');
  }
}
export function* createProgramSaga({ program }: types.CreateProgramRequest) {
  try {
    yield put(programsLoading());
    yield put(clearSelectedProgram())
    const response: SagaReturnType<typeof programAPI.createProgram> =
      yield programAPI.createProgram(program);
    yield put(createProgramSuccess(response));
    yield call(forwardTo, `/coach/programs/${response.id}/0`);
    yield put(programsLoaded());
  } catch (error: any) {
    yield put(programsFail(error));
  }
}
export function* addProgramWeekSaga({ programId }: types.AddProgramWeekRequest) {
  try {
    yield put(programsLoading());
    const response: SagaReturnType<typeof programAPI.addProgramWeek> =
      yield programAPI.addProgramWeek(programId);
    if (response) {
      yield put(addProgramWeekSuccess(programId));
    }

    yield put(programsLoaded());
  } catch (error: any) {
    yield put(programsFail(error));
  }
}
export function* removeProgramWeekSaga({ programId, weekIndex }: types.DeleteProgramWeekRequest) {
  try {
    const program = selectProgramConfig(store.getState());
    let weeks = [...program!.plan];
    let week = weeks.splice(weekIndex);
    yield Object.values(week![0]).forEach((day: ProgramConfigSession[], index) => {
      day.forEach(config => {
        return sessionActions.deleteSession(index as Day, weekIndex, config);
      });
    });
    const updatedProgram: SagaReturnType<typeof programAPI.removeProgramWeek> =
      yield programAPI.removeProgramWeek(programId, weekIndex);
    if (updatedProgram !== 'success') {
      yield put(getProgramSuccess(updatedProgram));
    } else {
      let week_no = program?.no_weeks! - 1;
      yield put(
        removeProgramWeekSuccess(new ProgramConfigClass({ no_weeks: week_no, plan: weeks })),
      );
    }

    // yield put(programsLoaded());
  } catch (error: any) {
    yield console.log(error);
    yield put(programsFail(error));
  }
}
export function* updateProgramSaga({ program }: types.UpdateProgramRequest) {
  try {
    yield put(programsLoading());
    const response: SagaReturnType<typeof programAPI.updateProgram> =
      yield programAPI.updateProgram(program);

    yield put(updateProgramSuccess(response));
    yield put(programsLoaded());
  } catch (error: any) {
    yield put(programsFail(error));
  }
}
export function* updateProgramDetailsSaga({ programId }: types.UpdateProgramDetailsRequest) {
  try {
    yield put(programsLoading());
    const details = selectProgramDetails(store.getState(), programId);
    const response: SagaReturnType<typeof programAPI.updateProgramDetails> =
      yield programAPI.updateProgramDetails(programId, details);
    const { selectedProgram, workouts, sessions, exercise_cards } = yield reduceProgram(
      response.program,
    );
    yield put(getProgramSuccess(selectedProgram));
    yield put(getWorkoutsSuccess(workouts));
    yield put(getSessionsSuccess(sessions));
    yield put(getExerciseCardsSuccess(exercise_cards));
    yield put(programsLoaded());
  } catch (error: any) {
    yield put(programsFail(error));
  }
}
export function* updateClientInputsSaga({
  programId,
  feedbackData,
}: types.UpdateClientInputsRequest) {
  try {
    yield put(programsLoading());
    const details = selectProgramClientDetails(store.getState(), programId);
    const response: SagaReturnType<typeof updateClientInputs> = yield updateClientInputs({
      inputs: details,
      feedbackData,
    });
    yield put(updateProgramMessage(response.message));
    yield put(programsLoaded());
  } catch (error: any) {
    yield put(programsFail(error));
  }
}
export function* deleteProgramSaga({ programId }: types.DeleteProgramRequest) {
  try {
    yield put(programsLoading());
    const response: SagaReturnType<typeof programAPI.deleteProgram> = yield call(
      programAPI.deleteProgram,
      programId,
    );
    yield put(deleteProgramSuccess(programId));
    yield put(programsLoaded());
  } catch (error: any) {
    yield put(programsFail(error));
  }
}
export function* createTemplateSaga({ name, programId }: types.CreateTemplateRequest) {
  try {
    yield put(programsLoading());
    const response: SagaReturnType<typeof programAPI.createTemplate> =
      yield programAPI.createTemplate(name, programId);
    yield put(getTemplateSuccess(response));
    yield put(programsLoaded());
  } catch (error: any) {
    yield put(programsFail(error));
  }
}

export function* generateProgramSaga({ programDetails }: types.GenerateProgram) {
  try {
    yield put(programsLoading());
    yield put(clearSelectedProgram())
    const response: SagaReturnType<typeof programAPI.generateProgram> =
      yield programAPI.generateProgram(programDetails);
    const { selectedProgram, workouts, sessions, exercise_cards } = yield reduceProgram(response);
    yield put(createProgramSuccess(selectedProgram));
    yield put(getWorkoutsSuccess(workouts));
    yield put(getSessionsSuccess(sessions));
    yield put(getExerciseCardsSuccess(exercise_cards));
    yield put(programsLoaded());
  } catch (error: any) {
    yield put(programsFail(error));
  }
}

export function* setSelectedProgramSaga({programId}: types.SetSelectedProgram) {
  try {
    yield put(programsLoading()); 
    yield put(setSelectedById(programId))
    yield put(programsLoaded())
  } catch (error: any) {
    yield put(programsFail(error))
  }
}

export function* clearProgramSaga(action: any) {
  try {
    yield put(clearSelectedProgram())
    yield put(sessionSlice.actions.clearSelectedSession());
    yield put(workoutSlice.actions.clearSelectedWorkout());
    yield put(programSlice.actions.removeProgramMessage());
  } catch (error: any) {
    yield put(programsFail(error))
  }
}

export function* programSaga() {
  yield takeLatest(actionTypes.GET_PROGRAMS, getProgramsSaga);
  yield takeLatest(actionTypes.CLIENT_GET_PROGRAMS, clientGetProgramsSaga);
  yield takeLatest(actionTypes.GET_PROGRAM_WORKOUTS, getProgramWorkoutsSaga);
  yield takeLatest(actionTypes.CREATE_PROGRAM_REQUEST, createProgramSaga);
  yield takeLatest(actionTypes.UPDATE_PROGRAM_REQUEST, updateProgramSaga);
  yield takeLatest(actionTypes.UPDATE_PROGRAM_DETAILS_REQUEST, updateProgramDetailsSaga);
  yield takeLatest(actionTypes.DELETE_PROGRAM_REQUEST, deleteProgramSaga);
  yield takeLatest(actionTypes.DELETE_PROGRAM_WEEK_REQUEST, removeProgramWeekSaga);
  yield takeLatest(actionTypes.ADD_PROGRAM_WEEK, addProgramWeekSaga);
  yield takeLatest(actionTypes.UPDATE_CLIENT_INPUTS_REQUEST, updateClientInputsSaga);
  yield takeLatest(actionTypes.GET_CLIENT_PROGRAM_WORKOUTS, clientGetProgramSaga);
  yield takeLatest(actionTypes.CREATE_TEMPLATE_REQUEST, createTemplateSaga);
  yield takeLatest(actionTypes.GENERATE_PROGRAM_REQUEST, generateProgramSaga);
  yield takeLatest(actionTypes.SET_SELECTED_PROGRAM, setSelectedProgramSaga);
  yield takeLatest(actionTypes.CLEAR_PROGRAM, clearProgramSaga);
  // yield takeLatest(REFRESH_TOKEN_REQUEST, doRefreshToken);
}


import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../style/SideBar.scss';
import { User } from '../tsUtils/userTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import * as actions from '../store/auth/actions';
import { Logout } from '@mui/icons-material';
import UserAvatar from './UserAvatar';

interface Props {
  menu1: {
    icon: React.ReactNode;
    title: string;
    link: string;
    staff_only?: boolean;
  }[];
  open: boolean;
}

function SideBar(props: Props) {
  const [selected, setselectedState] = useState<string>();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.user);

  useEffect(() => {
    const currentPath = location.pathname.split('/');
    setselectedState(`/${currentPath[1]}/${currentPath[2]}`);
  }, [location]);

  // Changes style of selected item

  // Creates menu items
  let menu1 = props.menu1.map(item => {
    if (item.staff_only && !user.is_staff) {
      return null;
    }
    return (
    <li title={item.title} key={item.title}>
      {selected === item.link ? (
        <div className="selectedCont">
          <Link to={item.link} style={{ fontSize: '1rem', fontWeight: '500' }} className="link">
            {item.icon} <span className="menu-text">{item.title}</span>
          </Link>
        </div>
      ) : (
        <div className="itemCont">
          <Link to={item.link} style={{ fontSize: '1rem' }} className="link">
          {item.icon} <span className="menu-text">{item.title}</span>
          </Link>
        </div>
      )}
    </li>
  )});
  return (
    <div className={props.open ? 'sidebar-expanded' : 'sideBarClosed'}>
      <div className="top-container">
        <div className="user-message">
          <UserAvatar size={50} first={user.first_name} last={user.last_name} />
        </div>
      </div>
      <div className="sideBarMain">
        <ul className="mainMenu">{menu1}</ul>
      </div>
      <Link className="link" to="/" onClick={() => dispatch(actions.logout())}>
          <Logout/><Typography className="menu-text" component="span">Logout</Typography>
        </Link>
    </div>
  );
}

export default SideBar;

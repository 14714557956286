import { Assessment, AssessmentResult } from "../../tsUtils/assessmentTypes";

export const actionTypes = {
  // List Assessment
  GET_ASSESSMENTS: '[Assessments] Get Assessments',
  GET_ASSESSMENTS_SUCCESS: '[Assessments] Get Assessments Success',
  GET_ASSESSMENTS_FAIL: '[Assessments] Get Assessments Failure',

  // Get Assessment
  GET_ASSESSMENT: '[Assessments] Get Assessment',
  GET_ASSESSMENT_SUCCESS: '[Assessments] Get Assessment Success',
  GET_ASSESSMENT_FAIL: '[Assessments] Get Assessment Failure',

  // Update Assessment
  UPDATE_ASSESSMENT_REQUEST: '[Assessments] Update Assessment Request',
  UPDATE_ASSESSMENT_SUCCESS: '[Assessments] Update Assessment Success',
  UPDATE_ASSESSMENT_FAIL: '[Assessments] Update Assessment Failure',

  CREATE_ASSESSMENT_REQUEST: '[Assessments] Create Assessment Request',
  CREATE_ASSESSMENT_SUCCESS: '[Assessments] Create Assessment Success',
  CREATE_ASSESSMENT_FAIL: '[Assessments] Create Assessment Failure',

  CLEAR_ASSESSMENT: '[Assessments] Clear Assessment',
  CLEAR_ASSESSMENT_SUCCESS: '[Assessments] Clear Assessment Success',
  CLEAR_ASSESSMENT_FAIL: '[Assessments] Clear Assessment Failure',

  DELETE_ASSESSMENT_REQUEST: '[Assessments] Delete Assessment Request',
  DELETE_ASSESSMENT_SUCCESS: '[Assessments] Delete Assessment Success',
  DELETE_ASSESSMENT_FAIL: '[Assessments] Delete Assessment Fail',

  GET_ASSESSMENT_RESULTS: '[Assessments] Get Assessment Results',
  GET_ASSESSMENT_RESULTS_SUCCESS: '[Assessments] Get Assessment Results Success',
  GET_ASSESSMENT_RESULTS_FAIL: '[Assessments] Get Assessment Results Failure',

  CREATE_ASSESSMENT_RESULT_REQUEST: '[Assessments] Create Test Result Request',
  CREATE_ASSESSMENT_RESULT_SUCCESS: '[Assessments] Create Test Result Success',
  CREATE_ASSESSMENT_RESULT_FAIL: '[Assessments] Create Test Result Failure',

  DELETE_ASSESSMENT_RESULT_REQUEST: '[Assessments] Delete Test Result Request',
  DELETE_ASSESSMENT_RESULT_SUCCESS: '[Assessments] Delete Test Result Success',
  DELETE_ASSESSMENT_RESULT_FAIL: '[Assessments] Delete Test Result Failure',

  UPDATE_ASSESSMENT_RESULT_REQUEST: '[Assessments] Update Test Result Request',
  UPDATE_ASSESSMENT_RESULT_SUCCESS: '[Assessments] Update Test Result Success',
  UPDATE_ASSESSMENT_RESULT_FAIL: '[Assessments] Update Test Result Failure',

};

export const assessmentActions = {
  getAssessments: () => {
    return {
      type: actionTypes.GET_ASSESSMENTS,
    };
  },
  getAssessment: (id: number) => {
    return {
      type: actionTypes.GET_ASSESSMENT,
      id,
    };
  },
  getAssessmentSuccess: (assessment: Assessment & {results: AssessmentResult[]}) => {
    return {
      type: actionTypes.GET_ASSESSMENT_SUCCESS,
      assessment,
    };
  },
  getAssessmentsSuccess: (assessments: Assessment[]) => {
    return {
      type: actionTypes.GET_ASSESSMENTS_SUCCESS,
      assessments,
    };
  },
  updateAssessment: (assessment: Assessment) => {
    return {
      type: actionTypes.UPDATE_ASSESSMENT_REQUEST,
      assessment,
    };
  },
  createAssessment: (assessment: Assessment, navigate: any) => {
    return {
      type: actionTypes.CREATE_ASSESSMENT_REQUEST,
      assessment,
      navigate
    };
  },
  clearAssessment: () => {
    return {
      type: actionTypes.CLEAR_ASSESSMENT,
    };
  },
  deleteAssessment: (id: number) => {
    return {
      type: actionTypes.DELETE_ASSESSMENT_REQUEST,
      id,
    };
  },
  getAssessmentResults: (assessment_id: string) => {
    return {
      type: actionTypes.GET_ASSESSMENT_RESULTS,
      assessment_id,
    };
  },
  getAssessmentResultsSuccess: (assessment_results: AssessmentResult[]) => {
    return {
      type: actionTypes.GET_ASSESSMENT_RESULTS_SUCCESS,
      assessment_results,
    };
  },
  createAssessmentResult: (assessmentResult: AssessmentResult) => {
    return {
      type: actionTypes.CREATE_ASSESSMENT_RESULT_REQUEST,
      assessmentResult,
    };
  },
  createAssessmentResultSuccess: (assessmentResult: AssessmentResult) => {
    return {
      type: actionTypes.CREATE_ASSESSMENT_RESULT_SUCCESS,
      assessmentResult,
    };
  },
  deleteAssessmentResult: (id: number) => {
    return {
      type: actionTypes.DELETE_ASSESSMENT_RESULT_REQUEST,
      id,
    };
  },
  deleteAssessmentResultSuccess: (id: number) => {
    return {
      type: actionTypes.DELETE_ASSESSMENT_RESULT_SUCCESS,
      id,
    };
  },
  updateAssessmentResult: (assessmentResult: AssessmentResult) => {
    return {
      type: actionTypes.UPDATE_ASSESSMENT_RESULT_REQUEST,
      assessmentResult,
    };
  },
  updateAssessmentResultSuccess: (assessmentResult: AssessmentResult) => {
    return {
      type: actionTypes.UPDATE_ASSESSMENT_RESULT_SUCCESS,
      assessmentResult,
    };
  },

};
import * as React from 'react';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import PopupMenu from './PopupMenu';
import { MoreVert } from '@mui/icons-material';

export interface IPageActionsProps {
  backButton: { link: string; label: string };
  backAction?: () => void;
  actionButtons?: { action: () => void; icon?: React.ReactNode; label?: string, disabled?: boolean }[];
  buttonSize?: 'small' | 'medium' | 'large';
}

const sizes = {
  'small': '40px',
  'medium': '50px',
  'large': '60px',
}

const styles = {
  navStuff: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    left: '5px',
  },
  button: {
    height: '50px',
    display : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: "5px"
  },
};
export function PageActions(props: IPageActionsProps) {
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const backButtonOnClick = (event: React.SyntheticEvent) => {
    if (!props.backAction) return;
    event.preventDefault();
    dispatch(props.backAction());
    navigate(props.backButton.link);
  }
  const actionButtonsDesktop = props.actionButtons?.map((button, index) => {
    return (
      <Button style={styles.button} key={index} disabled={button.disabled} onClick={button.action}>
        {button.icon} {button.label} 
      </Button>
    );
  });

  return (
    <div style={styles.navStuff}>
      {props.backAction && !window.location.href.includes('print') ?

        <Button onClick={backButtonOnClick} style={styles.button} >
          <ArrowBackIcon></ArrowBackIcon>
          {props.backButton.label}
        </Button>

        :
        <Link to={props.backButton.link}>
          <Button style={styles.button} >
            <ArrowBackIcon></ArrowBackIcon>
            {props.backButton.label}
          </Button>
        </Link>
      }

      <div className="button_cont">{
        isMobile && props.actionButtons && props.actionButtons.length > 1 ? 
          <div className="config-item-pill-actions">
            <PopupMenu popoverId={`page_actions_pop-up`}
              triggerStyle={{
                color: '#888',
                background: 'white',
                height: props.buttonSize ? sizes[props.buttonSize] : '40px',
                width: props.buttonSize ? sizes[props.buttonSize] : '40px',
                fontSize: '1.8em',
                cursor: 'pointer',
                borderRadius: '50%',
                border: '1px solid #aaa'
              }}
              icon={<MoreVert fontSize="medium" />}
              menuItems={
                props.actionButtons?.map((button, index) => {
                  return {
                    text: button.label as any,
                    icon: button.icon as any,
                    action: button.action
                  }
                })
              } ></PopupMenu>
          </div>
          : 
          actionButtonsDesktop
      }</div>
    </div>
  );
}

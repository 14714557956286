import { Box } from "@mui/system";
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import * as types from '../../../tsUtils';
import '../../../style/Coach/Program.scss';
import { programActions } from '../../../+store/programs/actionTypes';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material';
import { ListUserInterface } from '../../../tsUtils';

interface Props {
  program: types.ProgramInterface;
}

interface ProgramForm {
  name: string;
  quadrennial: string;
  macro: string;
  meso: string;
}

function ProgramHead(props: Props) {

  const loading = useSelector((state: RootState) => state.programs.loading);

  const user = useSelector((state: RootState) => state.auth.user);

  const { control, handleSubmit, setValue, formState } = useForm<ProgramForm>({
    defaultValues: props.program
      ? {
          name: props.program.name,
          quadrennial: props.program.quadrennial,
          macro: props.program.macro,
          meso: props.program.meso,
        }
      : {
          name: '',
          quadrennial: '',
          macro: '',
          meso: '',
        },
  });

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<ProgramForm> = data => {
    dispatch(
      programActions.updateProgram({
        ...props.program,
        creator: props.program.creator.id,
        client: props.program.client.map((client: ListUserInterface) => client.id),
        viewers: props.program.viewers ? props.program.viewers.map((viewer: ListUserInterface) => viewer.id) : null,
        name: data.name,
        quadrennial: data.quadrennial,
        macro: data.macro,
        meso: data.meso,
      }),
    );
  };

  return (
    <Box className="programHead">
      <div className="program-form-cont">
        {loading ? (
          <CircularProgress />
        ) : (
          <form className="program-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="program-title">
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    className="title-input"
                    id="title-input"
                    {...field}
                    title="Program Name"
                    variant="standard"
                    size="small"
                    label="Program Name"
                  />
                )}
              />
            </div>
            <div className="program-form-controls">
              <Controller
                control={control}
                name="quadrennial"
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value || ''}
                    variant="filled"
                    label="Quadrennial Cycle"
                    title="Program Quadrennial Cycle"
                    size="small"
                  />
                )}
              />
              <Controller
                control={control}
                name="macro"
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value || ''}
                    variant="filled"
                    title="Program Macro Cycle"
                    label="Macro Cycle"
                    size="small"
                  />
                )}
              />
              <Controller
                control={control}
                name="meso"
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value || ''}
                    variant="filled"
                    title="Program meso-cycle"
                    size="small"
                    label="Meso Cycle"
                  />
                )}
              />
            </div>
            <div className="form-buttons">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
              >
                Edit
              </Button>
            </div>
          </form>
        )}
      </div>
    </Box>
  );
}

export default ProgramHead;

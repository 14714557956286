import moment from "moment";
import { User, ExerciseCardClass } from '../../tsUtils';
import * as paths from './paths';
import { getAuthHeader } from './paths';

export async function getExerciseCards(query: string): Promise<ExerciseCardClass[]> {
  let endpoint = `${paths.COACHCARD}${query}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function createExerciseCard(
  exerciseCard: ExerciseCardClass,
  sectionIndex: number,
): Promise<Response> {
  let endpoint = `${paths.COACHCARD}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify({exercise_card: exerciseCard, section_index: sectionIndex}),
  })
}
export async function updateExerciseCard(exerciseCard: ExerciseCardClass): Promise<Response> {
  let endpoint = `${paths.COACHCARD}/${exerciseCard.id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(exerciseCard),
  })
}
export async function deleteExerciseCard(id: number, sectionIndex: number): Promise<Response> {
  let endpoint = `${paths.COACHCARD}/${id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: "DELETE",
    headers,
    body: JSON.stringify({section_index: sectionIndex}),
  })
}

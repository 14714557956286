import React from 'react';
import { TextField } from '@mui/material';

import { styled } from '@mui/system';
export interface StyledInputProps {
  height?: string;
  textalign?: string;
  backgroundcolor?: string;
  border?: string;
}
export const StyledInput = styled(TextField)((props: StyledInputProps) =>`
  & .MuiOutlinedInput-input {
    height: ${props.height || '20px'};
    border: ${props.border || 'none'};
    width: 100%;
    background: ${props.backgroundcolor || '#eee'};
    border-radius: 4px;
    text-align: ${props.textalign || 'center'};
  }
  & .MuiOutlinedInput-notchedOutline {
    border: ${props.border || 'none'};
    text-align: ${props.textalign || 'center'};
    background: ${props.backgroundcolor + '00' || '#eeeeee00'};
  }
  & .MuiOutlinedInput-input {
    padding: 6px 8px;
  }
  & .MuiInputLabel-formControl {
    top: -13px;
    line-height: 2.5em;
  }
  & .MuiInputBase-root {
    border: ${props.border || 'none'};
    font-size: 1em;
    padding: 0px;
    background: ${props.backgroundcolor || '#eee'};
  }
`);
export const StyledSmallInput = styled(TextField)((props: StyledInputProps) =>`
  & .MuiOutlinedInput-input {
    height: ${props.height || '20px'};
    margin: none;
    border: none;
    width: 100%;
    background: #eee;
    border-radius: 4px;
    text-align: ${props.textalign || 'center'};
  }
  & .MuiOutlinedInput-notchedOutline {
    margin: none;
    border: none;
    text-align: ${props.textalign || 'center'};
    background: #eeeeee00;
  }
  & .MuiInputBase-input {
    padding: 6px 2px;
  }

  & .MuiOutlinedInput-input {
    padding: 6px 2px;
  }
  & .MuiInputLabel-formControl {
    top: -2px;
  }
`);

import { Definition, DefinitionClassification } from '../../../tsUtils';
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { createSelector } from 'reselect';

export interface DefinitionState extends EntityState<Definition> {
  loading: boolean;
  loaded: Boolean;
  error: any;
}

const definitionAdapter = createEntityAdapter<Definition>({});

export const definitionInitialState: DefinitionState = definitionAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

export const inputsSlice = createSlice({
  name: 'inputs',
  initialState: definitionInitialState,
  reducers: {
    definitionsLoading(state) {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    },
    definitionsLoaded(state) {
      state.loading = false;
      state.loaded = true;
      state.error = null;
    },
    definitionsFail(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
    getDefinitionsSuccess(state, action) {
      definitionAdapter.upsertMany(state, action.payload);
    },
  },
});

export const { definitionsLoading, definitionsLoaded, definitionsFail, getDefinitionsSuccess } =
  inputsSlice.actions;

export const definitionsSelectors = definitionAdapter.getSelectors<RootState>(
  state => state.definitions.inputs,
);

export const selectinput_types = createSelector(
  definitionsSelectors.selectAll,
  (definitions: Definition[]): Definition[] => {
    return definitions.filter(
      definition => definition.classification === DefinitionClassification.InputVariable,
    );
  },
);

export const selectInputTypesAsObject = createSelector(
  selectinput_types,
  (definitions: Definition[]): { [key: number]: Definition } => {
    return definitions.reduce((acc, definition) => {
      acc[definition.id!] = definition;
      return acc;
    }, {} as { [key: number]: Definition });
  },
);


import React, { useEffect, useState, useCallback } from 'react';
import CardInputs from './CardInputs';
import { useDispatch, useSelector } from 'react-redux';
import Confirmation from '../../util/Confirmation';
import * as types from '../../../tsUtils';
import BaseDialog from '../dialogs/BaseDialog';
import {
  Definition,
  ExerciseCardClass,
  InputGroup,
} from '../../../tsUtils';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, CircularProgress, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { RootState } from '../../../store/store';
import * as appActions from '../../../store/app/actions';
import TimeInput from '../../util/TimeInput';
import { exerciseCardActions } from '../../../+store/exerciseCards/actionTypes';
import { debounce, set } from 'lodash';
import '../../../style/Coach/Card.scss';
import { StyledInput } from '../../../common/StyledInput';
import ExerciseSearch from './ExerciseSearch';
import { useCardTotals } from '../../../common/hooks/useCardTotals';
import { selectClientInputs } from '../../../+store/exerciseCards/reducers';

interface Props {
  card: ExerciseCardClass;
  index: number;
  remove: (id: number) => void;
  inputOptions: { [key: string]: Definition };
  id: number;
}

function ExerciseCardForm(props: Props) {
  const client = useSelector((state: RootState) => state.exercise_cards.currentClient);
  const client_inputs = useSelector((state: RootState) => selectClientInputs(state, props.card.id!));
  const [useUserExercise, setUseUserExercise] = useState<boolean>(
    props.card.user_exercise ? true : false
  );
  const [selectedExercise, setselectedExercise] = useState<types.ExerciseBaseInterface>(
    props.card.exercise ?? ({} as types.ExerciseBaseInterface),
  );
  const [card, setCard] = useState<ExerciseCardClass>(props.card);
  const [dirty, setDirty] = useState(false);
  const [totals, updateTotals, totalsLoading] = useCardTotals();
  const dispatch = useDispatch();
  const save = (formData: ExerciseCardClass) => {
    delete formData.client_inputs
    dispatch(
      exerciseCardActions.updateExerciseCard({
        ...formData,
      }),
    );
  };

  useEffect(() => {
    if (card && client_inputs) {
      updateTotals(card, client_inputs.inputs);
    }
    else if (card && card.config.inputs) {
      updateTotals(card, card.config.inputs);
    }
  }, [card, client_inputs]);

  const intervalSave = useCallback(
    debounce(async (newCard) => {
      await save(newCard);
    }, 2000),
    [],
  );

  const onError = (error: any, e: any) => console.log(error);

  return (
    <div className="exercise_card">
      <div className="cardHead">
        <div className="head_item">
          <input
            className="cardNo"
            type="text"
            onChange={(e) => {
              setCard({ ...card, config: { ...card.config, card_no: e.target.value } });
              setDirty(true);
              intervalSave({ ...card, config: { ...card.config, card_no: e.target.value } });
            }}
            defaultValue={props.card.config.card_no}
            name="card_no"
          />
        </div>
        <div className="head_item">
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <ExerciseSearch
              selectedExercise={selectedExercise}
              cardIndex={props.index}
              onSelectExercise={exercise => {
                const newCard = { ...card, exercise };
                setCard({ ...card, exercise });
                setDirty(true);
                intervalSave(newCard);
                setselectedExercise(exercise);
              }}
            />
            <FormControlLabel
              slotProps={{
                typography: {
                  variant: "body1",
                  fontSize: 'small',
                  component: "span"
                }
              }}
              sx={{
                marginBottom: '0px',
                marginTop: '5px',
                fontSize: 'x-small',
                marginRight: '0px'
              }}
              labelPlacement="top"
              control={
                <Switch checked={card.auto_prescribe} sx={{ marginTop: '-10px' }} onChange={
                  (e) => {
                    const newCard = { ...card, auto_prescribe: e.target.checked };
                    setCard({ ...card, auto_prescribe: e.target.checked })
                    setDirty(true);
                    intervalSave(newCard);
                  }
                } />
              }
              label="Prescribe"
            />
          </Box>
        </div>
        <div className="head_item">
          <BaseDialog
            title={'Confirm'}
            buttonSize="large"
            buttonText={<DeleteIcon fontSize="inherit" />}
            buttonColour="error"
            buttonWidth={30}
            fab
          >
            <Confirmation
              cancel={() => dispatch(appActions.closeDialogs())}
              confirm={() => props.remove(props.id)}
              confirmcolour="#d94136"
              confirmmessage="Delete"
              message="Are you sure you want to delete this exercise card?"
            />
          </BaseDialog>
        </div>
      </div>
      <div className="coach-variation-cont" >
        <div className="coach-variation">

          <StyledInput
            value={card.config.variation_1}
            onChange={(e) => {
              setCard({ ...card, config: { ...card.config, variation_1: e.target.value } });
              setDirty(true);
              intervalSave({ ...card, config: { ...card.config, variation_1: e.target.value } });
            }}
            variant="outlined"
            className="variable"
            size="small"
            title="Variation 1"
            label="Variation 1"
          />
        </div>
        <div className="coach-variation">
          <StyledInput
            value={card.config.variation_2}
            onChange={(e) => {
              setCard({ ...card, config: { ...card.config, variation_2: e.target.value } });
              setDirty(true);
              intervalSave({ ...card, config: { ...card.config, variation_2: e.target.value } });
            }}
            variant="outlined"
            className="variable"
            size="small"
            title="Variation 2"
            label="Variation 2"
          />
        </div>
        <div className="coach-variation">

          <StyledInput
            value={card.config.equipment}
            onChange={(e) => {
              setCard({ ...card, config: { ...card.config, equipment: e.target.value } });
              setDirty(true);
              intervalSave({ ...card, config: { ...card.config, equipment: e.target.value } });
            }}
            variant="outlined"
            className="variable"
            size="small"
            title="Equipment"
            label="Equipment"
          />
        </div>

        <div className="coach-variation">
          <StyledInput
            value={card.config.adjust_max}
            onChange={(e) => {
              setCard((newCard) => {
                intervalSave({ ...newCard, config: { ...newCard.config, adjust_max: e.target.value } });
                return { ...newCard, config: { ...newCard.config, adjust_max: e.target.value } }
              });
              setDirty(true);
              
            }}
            variant="outlined"
            title="Adjusted Max"
            size="small"
            className="variable"
            label="Adjusted Max"
          />
        </div>

        <div className="coach-variation">

          <StyledInput
            value={card.config.tempo}
            onChange={(e) => {
              setCard((newCard) => {
                intervalSave({ ...newCard, config: { ...newCard.config, tempo: e.target.value } })
                return { ...newCard, config: { ...newCard.config, tempo: e.target.value } }
              });
              setDirty(true);
              ;
            }}
            variant="outlined"
            className="variable"
            title="Tempo"
            size="small"
            label="Tempo"
            inputProps={{ pattern: '[0-9]/[0-9]/[0-9]/[0-9]' }}
          />
        </div>

        <div className="coach-variation">
          <TimeInput
            onChange={(value) => {
              setCard((newCard) => {
                intervalSave({ ...newCard, config: { ...newCard.config, rest: value } })
                return { ...newCard, config: { ...newCard.config, rest: value } }
              });
              setDirty(true);
              ;
            }}
            value={card.config.rest}
            format="mm:ss"
            size="medium"
            className="variable"
          />
        </div>
      </div>
      <Grid>
        {props.card.config.inputs && (
          <CardInputs
            inputOptions={Object.values(props.inputOptions)}
            cardIndex={props.index}
            inputs={client_inputs ? client_inputs.prescribed : card.config.inputs}
            onInputChange={(inputs: InputGroup) => {
              setCard((newCard) => {
                intervalSave({ ...newCard, config: { ...newCard.config, inputs } });
                return { ...newCard, config: { ...newCard.config, inputs } }
              });
              setDirty(true);

            }}
            client={client}
          />)
        }
        {client_inputs ? (
          <StyledInput
            maxRows={3}
            className="cardComment"
            label="Comment"
            size="small"
            multiline
            disabled
            value={client_inputs.comment}
            height='45px'
            inputProps={{ maxLength: 400 }}
          />
        ) : (

          <StyledInput
            value={card.comment || ''}
            onChange={(e) => {
              setCard({ ...card, comment: e.target.value });
              setDirty(true);
              intervalSave({ ...card, comment: e.target.value });
            }}
            maxRows={3}
            className="cardComment"
            label="Comment"
            multiline
            height='45px'
            fullWidth
            textalign='left'
            inputProps={{ maxLength: 400 }}
          />
        )}
      </Grid>
      <div className="variation-cont">
        {
          !totalsLoading ?
            Object.entries(totals).map(([key, value]: [string, string], index) => {
              if (value && !value.includes('NaN')) {
                return (
                  <div key={props.card.id + key} className="variation">
                    <Typography variant="button" component="span">
                      {key}:
                    </Typography>
                    <Typography variant="body1">
                      {value}
                    </Typography>
                  </div>
                );
              }
            }) :
            (
              <CircularProgress />
            )
        }
      </div>
    </div>
  );
}

export default ExerciseCardForm;

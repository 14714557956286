import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useNavigate, useParams, Routes, Outlet } from 'react-router-dom';
import '../../style/Coach/Program.scss';
import '../../style/Coach/Workout.scss';
import { programActions } from '../../+store/programs/actionTypes';
import { RootState } from '../../store/store';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ProgramConfigClass, SessionFeedbackRequest } from '../../tsUtils';
import { workoutSlice, } from '../../+store/workouts/reducers';
import { sessionSlice, selectCurrentSession } from '../../+store/sessions/reducers';
import { clearSelectedProgram,} from '../../+store/programs/reducers';
import SessionFeedbackForm from './dialogs/SessionQuestionsForm';
import { PageActions } from '../../common/PageActions';
import { LocalPrintshopOutlined, SaveOutlined } from '@mui/icons-material';

interface Props {}

function ClientProgram(props: Props) {
  const { programId, workout_id, session_id } = useParams();
  const program = useSelector((state: RootState) => state.programs.selectedProgram);
  const message = useSelector((state: RootState) => state.programs.message);
  const session = useSelector((state: RootState) => selectCurrentSession(state));
  const [openFeedback, setOpenFeedback] = useState(false);
  const [config, setConfig] = useState<ProgramConfigClass | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useSelector((state: RootState) => state.app.isMobile);

  useEffect(() => {
    if (programId && !program) {
      dispatch(programActions.getClientProgramWorkouts(Number(programId)));
    }
    if (workout_id && session_id) {
      dispatch(sessionSlice.actions.setSelectedSessionId(Number(session_id)));
      dispatch(workoutSlice.actions.setSelectedWorkoutId(Number(workout_id)));
    }

  }, [programId, workout_id, session_id]);
  useEffect(() => {
    if (program && program.program_config) {
      setConfig(program.program_config);
    }
  }, [program]);

  function saveProgram() {
    if (programId) {
      dispatch(programActions.updateClientInputs(Number(programId)));
    }
  }

  function sendFeedback(data: SessionFeedbackRequest) {
    if (programId) {
      dispatch(programActions.updateClientInputs(Number(programId), data));
      setOpenFeedback(false);
    }
  }

  const printProgram = () => {
    saveProgram();
    navigate(`/client/program/${programId}/print`);
  };

  return programId ? (
    <div className="program-container">
      <div className="programBody">
        <div className="navStuff" style={{ marginBottom: '5px', marginTop: '5px' }}>
        <PageActions
          backButton={{ label: 'Back', link: workout_id ? `/client/programs/${programId}/` : '/client/programs/', }}
          backAction={workout_id ? undefined : clearSelectedProgram}
          buttonSize='small'
          actionButtons={[
            { label: 'Print', action: printProgram, icon: <LocalPrintshopOutlined />},
            { label: 'Save' , action: session && session?.questions?.length > 0 ? () => setOpenFeedback(true) : saveProgram, icon: <SaveOutlined /> },

          ]}
        />
          <Typography color="green">{message}</Typography>
          {session && session?.questions?.length > 0 && (
              <Dialog
                title="Session Feedback"
                open={openFeedback}
                fullScreen={isMobile}
              >
                <DialogTitle>Session Feedback</DialogTitle>
                <DialogContent>
                  <SessionFeedbackForm submitForm={sendFeedback} onCancel={() => setOpenFeedback(false)} session={session} />
                </DialogContent>
              </Dialog>
          )}
        </div>
        <Outlet />
      </div>
    </div>
  ) : (
    <Outlet />
  );
}

export default ClientProgram;

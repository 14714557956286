import React, { Component } from "react";
// import {getPrograms, getSessions} from '../services/API'
import DashTable from "./containers/DashTable";
import moment from "moment";
import '../../style/Coach/Dashboard.scss';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions1: {},
      sessions2: {},
      programs: {},
    };
  }
  componentDidMount() {
    //   getSessions(`?ordering=start`)
    //   .then(data =>{
    //     this.setState({
    //       sessions1: data
    //     }, () => console.log(this.state.sessions1))
    // })
    //   getSessions("?completed=true&ordering=complete_date")
    //   .then(data =>{
    //     this.setState({
    //       sessions2: data
    //     }, () => console.log(this.state.sessions2))
    //   })
    //   getPrograms(`?ordering=start`)
    //   .then(data =>{
    //     this.setState({
    //       programs: data
    //     }, () => console.log(this.state.programs))
    //   })
  }

  render() {
    return (
      <div className="dashContainer">
        <div className="dashItem">
          <DashTable
            sessionList={this.state.sessions1.results}
            itemsType="sessions"
          />
        </div>
        <div className="dashItem">
          <DashTable
            programList={this.state.programs.results}
            itemsType="programs"
          />
        </div>
        <div className="dashItem">
          <DashTable
            sessionList={this.state.sessions2.results}
            itemsType="sessions"
          />
        </div>
      </div>
    );
  }
}

export default Dashboard;

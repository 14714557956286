import { Assessment, AssessmentResult } from '../../tsUtils/assessmentTypes';
import * as paths from './paths';

export async function getAssessments(): Promise<Response> {
  let endpoint = `${paths.ASSESSMENT}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  })
}

export async function getAssessment(id: number): Promise<Response> {
  let endpoint = `${paths.ASSESSMENT}/${id}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  })
}

export async function updateAssessment(assessment: Assessment, id: number): Promise<Response> {
  let endpoint = `${paths.ASSESSMENT}/${id}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify({
      name: assessment.name,
      description: assessment.description,
      creator: assessment.creator,
      clients: assessment.clients,
      category: assessment.category,
      assessment_config: assessment.assessment_config,
    }),
  })
}

export async function createAssessment(assessment: Assessment): Promise<Response> {
  let endpoint = `${paths.ASSESSMENT}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify(assessment),
  })
}

export async function getAssessmentResults(assessment_id: string): Promise<Response> {
  let endpoint = `${paths.ASSESSMENT}/${assessment_id}/results`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  })
}

export async function createAssessmentResult(assessmentResult: AssessmentResult): Promise<Response> {
  let endpoint = `${paths.ASSESSMENT_RESULT}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify(assessmentResult),
  })
}

export async function updateAssessmentResult(assessmentResult: AssessmentResult): Promise<Response> {
  let endpoint = `${paths.ASSESSMENT_RESULT}/${assessmentResult.id}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(assessmentResult),
  })
}

export async function deleteAssessment(id: number): Promise<Response> {
  let endpoint = `${paths.ASSESSMENT}/${id}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'DELETE',
    headers,
  })
}

export async function deleteAssessmentResult(id: number): Promise<Response> {
  let endpoint = `${paths.ASSESSMENT_RESULT}/${id}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'DELETE',
    headers,
  })
}
import { TemplateInterface } from '../../tsUtils/templateTypes';

export enum TemplateActionTypes {
  TemplatesLoading = '[Templates] Templates Loading',
  TemplatesLoaded = '[Templates] Templates Loaded',
  TemplatesFail = '[Templates] Templates Fail',
  GetTemplatesRequest = '[Templates] Get Templates Request',
  GetTemplatesSuccess = '[Templates] Get Templates Success',
  GetTemplatesFail = '[Templates] Get Templates Fail',
  CreateTemplateSuccess = '[Templates] Create Template Success',
  CreateTemplateFail = '[Templates] Create Template Fail',
  CreateProgramFromTemplateRequest = '[Templates] Create Program From Template Request',
  CreateProgramFromTemplateSuccess = '[Templates] Create Program From Template Success',
  CreateProgramFromTemplateFail = '[Templates] Create Program From Template Fail',
  UpdateTemplateSuccess = '[Templates] Update Template Success',
  UpdateTemplateFail = '[Templates] Update Template Fail',
  DeleteTemplateRequest = '[Templates] Delete Template Request',
  DeleteTemplateSuccess = '[Templates] Delete Template Success',
  DeleteTemplateFail = '[Templates] Delete Template Fail',
}

export const templateActions = {
  templatesLoading: () => {
    return {
      type: TemplateActionTypes.TemplatesLoading,
    };
  },
  templatesLoaded: () => {
    return {
      type: TemplateActionTypes.TemplatesLoaded,
    };
  },
  templatesFail: (error: any) => {
    return {
      type: TemplateActionTypes.TemplatesFail,
      payload: error,
    };
  },
  getTemplatesRequest: (query: string) => {
    return {
      type: TemplateActionTypes.GetTemplatesRequest,
      query,
    };
  },
  getTemplatesSuccess: (templates: TemplateInterface[]) => {
    return {
      type: TemplateActionTypes.GetTemplatesSuccess,
      payload: templates,
    };
  },
  getTemplatesFail: (error: any) => {
    return {
      type: TemplateActionTypes.GetTemplatesFail,
      payload: error,
    };
  },
  createTemplateSuccess: (template: TemplateInterface) => {
    return {
      type: TemplateActionTypes.CreateTemplateSuccess,
      payload: template,
    };
  },
  createTemplateFail: (error: any) => {
    return {
      type: TemplateActionTypes.CreateTemplateFail,
      payload: error,
    };
  },
  createProgramFromTemplateRequest: (
    templateId: number,
    data: { start: Date; name: string; client: number[] },
  ) => {
    return {
      type: TemplateActionTypes.CreateProgramFromTemplateRequest,
      templateId,
      data,
    };
  },
  updateTemplateSuccess: (template: TemplateInterface) => {
    return {
      type: TemplateActionTypes.UpdateTemplateSuccess,
      payload: template,
    };
  },
  updateTemplateFail: (error: any) => {
    return {
      type: TemplateActionTypes.UpdateTemplateFail,
      payload: error,
    };
  },
  deleteTemplateRequest: (id: number) => {
    return {
      type: TemplateActionTypes.DeleteTemplateRequest,
      payload: id,
    };
  },
  deleteTemplateSuccess: (template: TemplateInterface) => {
    return {
      type: TemplateActionTypes.DeleteTemplateSuccess,
      payload: template,
    };
  },
  deleteTemplateFail: (error: any) => {
    return {
      type: TemplateActionTypes.DeleteTemplateFail,
      payload: error,
    };
  },
};

// popover component from Material UI with array of menu items
// imports 
import React, { ReactElement } from 'react';
import {Popper, List, ListItem, ListItemText, ListItemIcon, Paper, ClickAwayListener } from '@mui/material';
// props for menu items
interface Props {
    menuItems: {
        icon: JSX.Element;
        text: string;
        action: () => void;
        disabled?: boolean;
    }[];
    icon: JSX.Element;   
    popoverId: string;
    triggerStyle?: React.CSSProperties;
}
// styles
const styles = {
    trigger: {
        cursor: 'pointer',
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        alignText: 'center',
    },
    menuItem: {
        cursor: 'pointer',
    }
};
// component
export default function PopupMenu(props: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    // toggle popover
    const handleTogglePopover = (event: any) => {
        event.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    // open popover on click
    const open = Boolean(anchorEl);

  const id = open ? props.popoverId : undefined;

    return (
        <div>
            <div style={props.triggerStyle ? {...props.triggerStyle, ...styles.trigger} : styles.trigger} onClick={handleTogglePopover}>
                {props.icon}
            </div>
            
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClick={(event) => { event.stopPropagation(); setAnchorEl(null); }}
                placement="bottom-end"
                sx={{ zIndex: 9999 }}
            >
                <Paper sx={{bgcolor: 'background.paper' }}>
                    <ClickAwayListener onClickAway={() => { setAnchorEl(null); }}>
                        <List component="nav" aria-label="main mailbox folders">
                            {props.menuItems.map((item, index) => (
                                <ListItem disabled={item.disabled} sx={styles.menuItem} key={index} onClick={(event) => { item.action()}}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.text} />
                                </ListItem>
                            ))}
                        </List>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </div>
    );

}
import React, { useEffect, useState } from 'react';
import { EventBase, EventType, event_typeIcon, event_typeName } from '../../../tsUtils/eventTypes';
import {
  Select,
  TextField,
  Typography,
  Icon,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  Paper,
  IconButton,
  Link,
} from '@mui/material';
import { ColourPicker } from '../../../common/ColourPicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEvent, updateEvent } from '../../../+store/events/actionTypes';
import { RootState } from '../../../store/store';
import Close from '@mui/icons-material/Close';
import { NavLink } from 'react-router-dom';
import { NavigateNextOutlined } from '@mui/icons-material';

const style: any = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  padding: '1rem',
  borderRadius: '3px'
}
const gridItem = {
  display: 'flex',
  alignItems: 'center',
};
interface EventModelProps {
  event: EventBase | null;
  open: boolean;
  onClose: () => void;
}

export default function EditEventModal(props: EventModelProps) {
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  const [edit, setEdit] = useState(false)
  const [eventId, setEventId] = useState(props.event?.id);
  const [title, setTitle] = useState(props.event?.title);
  const [event_type, setEventType] = useState(props.event?.event_type);
  const [colour, setColour] = useState(props.event?.backgroundColor);
  const dispatch = useDispatch();
  const [start, setstart] = useState<Date | null>(
    props.event?.start ? new Date(props.event?.start) : new Date(),
  );
  const [end, setEnd] = useState<Date | null>(
    props.event?.end ? new Date(props.event?.end) : new Date(),
  );
  const event_typeOptions = Object.values(EventType).map(
    type =>
      event_typeName(type as EventType) && (
        <MenuItem key={type} value={type}>
          <Icon>{event_typeIcon(type as EventType)}</Icon> {event_typeName(type as EventType)}
        </MenuItem>
      ),
  );
  useEffect(() => {
    if (props.event && props.event.id !== eventId) {
      setEventId(props.event.id);
      setTitle(props.event.title);
      setEventType(props.event.event_type);
      setColour(props.event?.backgroundColor);
      setstart(new Date(props.event?.start));
      setEnd(new Date(props.event?.end));
    }
  }, [props.event]);

  function handleEdit() {
    dispatch(
      updateEvent(
        new EventBase({
          id: props.event?.id,
          title,
          event_type,
          backgroundColor: colour,
          start: start?.toISOString(),
          end: end?.toISOString(),
          creator: props.event?.creator,
          organiser: props.event?.organiser,
        }),
      ),
    );
    props.onClose();
  }
  function handleDelete() {
    dispatch(deleteEvent(props.event?.id!));
    props.onClose();
  }
  
  return (
    <Dialog open={props.open} fullScreen={isMobile && edit} onClose={props.onClose}>
      { props.event && !edit ? 
      <div style={{
        border:'solid 4px ' + colour!, height: isMobile? '100%' : 'auto' , ...style}}>
        {isMobile && 
        <span><IconButton onClick={props.onClose} size="small" sx={{margin: '-15px', float: 'right'}}><Close /></IconButton></span>}
        <Typography variant="h4">{event_typeName(event_type!)}</Typography>
        <Typography variant="button">
          {title}
        </Typography>
        <Typography variant="button"> 
          Start: {start?.toLocaleString()}
        </Typography>
        <Typography variant="button"> 
          End: {end?.toLocaleString()}
        </Typography>
        { props.event.url && 
          <NavLink to={'/coach' + props.event.url} >
            {[EventType.Conditioning, EventType.ResistanceTraining].includes(props.event.event_type) ? 
            'Go To Session' : 'Vist Link'  } <NavigateNextOutlined />
          </NavLink>      
        }   
        <div style={{ display: 'flex', width: '100%', alignItems: 'end', justifyContent: 'space-between' }}>
          <Button
            color="info"
            onClick={() => setEdit(true)}
            fullWidth={false}
            variant="contained"
            sx={{ width: '90px' }}
          >
            Edit
          </Button>
        </div>
      </div> 
      :
      <div style={{minWidth: '330px', ...style}} >
      <Typography variant="h5" component="div">
        Edit Event
      </Typography>

      <div style={gridItem}>
        <TextField
          value={title}
          title="title"
          label="Title"
          onChange={event => setTitle(event.target.value)}
          
          variant="filled"
          fullWidth
        ></TextField>
      </div>
      <div style={gridItem}>
        <FormControl fullWidth>
          <InputLabel>Event Type</InputLabel>
          <Select
            value={event_type}
            onChange={event => setEventType(event.target.value as EventType)}
            sx={{ display: 'flex', alignItems: 'center' }}
            
            label="Event Type"
            variant="filled"
          >
            {event_typeOptions}
          </Select>
        </FormControl>
      </div>
      
      <div style={gridItem}>
        <DateTimePicker
          label="Start"
          renderInput={(params: any) => (
            <TextField {...params}  variant="filled" fullWidth />
          )}
          value={start}
          onChange={(value: Date | null, input) => setstart(value)}
        />
      </div>
      <div style={gridItem}>
        <DateTimePicker
          label="End"
          renderInput={(params: any) => (
            <TextField {...params}  variant="filled" fullWidth />
          )}
          value={end}
          onChange={(newValue: Date | null, input) => {
            setEnd(newValue);
          }}
        />
      </div>
      <div style={gridItem}>
        <ColourPicker changeColour={(colour: string) => setColour(colour)} colour={colour} />
      </div>
      <div style={{ display: 'flex', width: '100%', alignItems: 'end', justifyContent: 'space-between' }}>
        <Button onClick={() => setEdit(false)} color="info" sx={{ width: '90px' }} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="error" sx={{ width: '90px' }} variant="outlined">
          Delete
        </Button>
        <Button
          color="primary"
          onClick={() => handleEdit()}
          fullWidth={false}
          variant="contained"
          sx={{ width: '90px' }}
        >
          Save
        </Button>
      </div>
    </div>

    }
      
      
    </Dialog>
  );
}

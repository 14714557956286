import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Saved from './common/Saved';
import Error from './common/Error';
import { Dialog } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { closeDialogs } from './store/app/actions';

export interface LayoutProps {
  isMobile: boolean;
}

export default function Layout(props: LayoutProps) {
  const [isMobile, setIsMobile] = React.useState(props.isMobile);
  const dialogChildren = useSelector((state: any) => state.app.dialogChildren);
  const dispatch = useDispatch();
  
  React.useEffect(() => {
    setIsMobile(props.isMobile);
  }, [props.isMobile]);
  
  return (
    <div className="App">
      <Outlet />
      <Saved />
      <Error />
      <Dialog fullScreen={isMobile} open={dialogChildren? true : false} onClose={() => {
        dispatch(closeDialogs());
      }}>
        <React.Suspense fallback={<div>Loading...</div>}>
          {dialogChildren && <dialogChildren.element  {...dialogChildren.props} />}
        </React.Suspense>
      </Dialog>
    </div>
  );
  
}

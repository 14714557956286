import React from 'react';
import { EventBase, event_typeIcon } from '../../../tsUtils/eventTypes';
import { Icon, Typography } from '@mui/material';

const dateOverlayCont = {
  width: '100%',
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'space-between',
  border: 'none',
  padding: '0px 5px',
};

function EventOverlay(props: { event: EventBase }) {
  return (
    <div
      title={props.event?.title}
      style={dateOverlayCont}
    >
      <span
        style={{
          width: '100%',
          cursor: 'pointer',
          display: 'flex',
          gap: '3px',
          alignItems: 'center',
          overflowX: 'clip',
        }}
      >
        <Icon style={{ fontSize: '16px' }}>
          {props.event?.event_type ? event_typeIcon(props.event?.event_type) : 'fitness_center'}
        </Icon>
        <Typography variant="caption" component="span" style={{ fontSize: 'small' }}>
          {props.event?.title.split(' - ')[0]}
        </Typography>
      </span>
    </div>
  );
}
export default EventOverlay;

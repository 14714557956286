import React, { useEffect, useState } from 'react';
import * as actions from '../store/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import UserAvatar from './UserAvatar';
import {  Refresh } from '@mui/icons-material';
const AuthTopBar = (props: any) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="topbar">
      <div className="leftContainer">
        {user && (
          <div>
          <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <UserAvatar size={40} first={user.first_name} last={user.last_name} />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => dispatch(actions.logout())}>Logout</MenuItem>
        </Menu>
      </div>
          
        )}
        
      </div>
      <div style={{ fontSize: '1.7em' }} className="centerContainer">
        <img style={{ width: 235 }} src="/CleverCoachTech-logos_transparent.png" alt="logo" />
      </div>
      <div className="rightContainer">
        <Button sx={{color: '#efefef'}} size="large" onClick={() => window.location.reload()}>
           <Refresh fontSize='large' />
        </Button>
      </div>
    </div>
  );
};

export default AuthTopBar;

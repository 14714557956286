export const BASE_URL = process.env.REACT_APP_API_URL || "https://api.clevercoach.training"
export const COACH = `${BASE_URL}/coachapp/`;
export const COACHLOGIN = `${COACH}login/`;
export const COACHLOGOUT = `${COACH}logout/`;
export const COACHPROGRAM = `${COACH}api/program`;
export const COACHWORKOUT = `${COACH}api/workout`;
export const COACHWORKOUTCOPY = `${COACH}api/workout/copy`;
export const COACHSESSION = `${COACH}api/session`;
export const COACHCARD = `${COACH}api/exercisecard`;
export const COACHCLIENT = `${COACH}api/clients`;
export const COACHEXERCISE = `${COACH}api/exercise`;
export const COACHEVENT = `${COACH}api/events`;
export const COACHDEFINITIONS = `${COACH}api/definitions`;
export const COACHEXERCISES = `${COACH}api/exercise`;
export const COACHEXERCISESEARCH = `${COACH}api/exercise/search`;
export const COACHTEMPLATES = `${COACH}api/template`;
export const COACHACCOUNT = `${COACH}api/account_details`;
export const COACHWARMUP = `${COACH}api/warmup`;
export const COACHCOOLDOWN = `${COACH}api/cooldown`;
export const GENERATEPROGRAM = `${BASE_URL}/ai/generate_program`;
export const GENERATEWORKOUT = `${BASE_URL}/ai/generate_workout`;
export const ASSESSMENT = `${COACH}api/assessment`;
export const ASSESSMENT_RESULT = `${COACH}api/assessment_result`;
export const SECTION_TEMPLATE = `${COACH}api/section_template`;


export const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `token ${token}`);
  headers.append("Accept", "application/json");
  return headers;
};

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { min, over } from 'lodash';
import { UserBest } from '../../tsUtils';
import { closeDialogs, openSlopeGraphDialog } from '../../store/app/actions';
import { OpenInNew } from '@mui/icons-material';

export interface ClientProps {

}

const ClientStyle = {
  container : {
    padding: '.5rem',
    overflow: 'auto',
    height: '100%',
    width: '100%',
  },
  main: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto',
    gap: '.5rem',
  },
  details: {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
  },
  detailsItem: {
    minWidth: '80px',
  },
  resultTableHeader: {
    display: 'grid',
    gridTemplateColumns: '30% 1fr 1fr',
    gap: '.5rem',
    margin: '0px -10px',
    padding: '10px',
  },
  resultTableEntry: {
    display: 'grid',
    cursor: 'pointer',
    gridTemplateColumns: '30% 1fr',
    gap: '.5rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.25)',
    alignItems: 'start',
    margin: '0px -10px',
    padding: '10px',
  },
  assessmentHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '.5rem',
  },
  assessmentEntry: {
    display: 'grid',
    cursor: 'pointer',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'start',
  },
  assessmentResults: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
  } as React.CSSProperties,
  bestsHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr 70px 70px 1fr',
    margin: '0px -10px',
    padding: '10px',
  },
  bestsEntry: {
    display: 'grid',
    gridTemplateColumns: '1fr 70px 70px 1fr',
    borderTop: '1px solid rgba(0, 0, 0, 0.25)',
    alignItems: 'center',
    margin: '0px -10px',
    padding: '10px',
  },
};

export default function Client (props: ClientProps) {
  const client = useSelector((state: RootState) => state.clients.selected_client);
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  const dispatch = useDispatch();

  const openSlopeCurve = (bests: UserBest) => {
    dispatch(
      openSlopeGraphDialog({
        exercise: bests.exercise.name, 
        coef: bests.bests.velocity.coefficients,
        intercept: bests.bests.velocity.intercept, 
        isMobile,
        onClose: () => dispatch(closeDialogs())
  }));
  }
  return (
    <div style={ClientStyle.container}>
      
    <div style={ClientStyle.main}>
      <div  className='card'>
        <h4 >Client Details</h4>
        <div style={ClientStyle.details as React.CSSProperties}>
          <div style={ClientStyle.detailsItem}>
            <strong>Name:</strong>
            <p>{client?.first_name} {client?.last_name}</p>
          </div>
          <div style={ClientStyle.detailsItem}>
            <strong>Email:</strong>
            <p>{client?.email}</p>
          </div>
          <div style={ClientStyle.detailsItem}>
            <strong>DOB:</strong>
            <p>{client?.metrics.dob ?? '-'}</p>
          </div>
          <div style={ClientStyle.detailsItem}>
            <strong>
              Height:
            </strong>
            <p>{client?.metrics.height ?? '-'}</p>
          </div>
          <div style={ClientStyle.detailsItem}>
            <strong>
              Weight:
            </strong>
            <p>{client?.metrics.weight ?? '-'}</p>
          </div>
        </div>
      </div>
      <div className='card'>
        <h4>Assessment Results</h4>
        <div style={ClientStyle.resultTableHeader}>
          <strong>Assessment Name</strong>
          <strong>Assessments</strong>
          <strong>Type</strong>
        </div>
        {client?.assessment_results.map((assessment, index) => (
          <div style={ClientStyle.resultTableEntry} key={index}>
            <p style={{marginBottom: 0}}>{assessment.assessment.name}</p>
            <div style={ClientStyle.assessmentResults}>
            {assessment.results.map((result, index) => (
              <div style={{ borderBottom: index < assessment.results.length -1 ? '1px solid rgba(0, 0, 0, 0.125)' : '0', ...ClientStyle.assessmentEntry}} key={index}>
                <p style={{marginBottom: index < assessment.results.length -1 ? '.5rem' : '0px'}} >{result.exercise?.name}</p>
                <p style={{marginBottom: index < assessment.results.length -1 ?'.5rem' : '0px'}} >{result.assessment_type}</p>
              </div>
            ))}
            </div>
          </div>
        ))}
      </div>
      <div className='card'>
        <h4>Bests</h4>
        <div style={ClientStyle.bestsHeader}>
          <strong>Exercise</strong>
          <strong>1RM</strong>
          <strong>MVT</strong>
          <strong>Velocity Curve</strong>
        </div>
        {client?.bests.map((best, index) => (
          <div 
          style={ClientStyle.bestsEntry}
          key={index}>
            <p style={{marginBottom: 0}}>{best.exercise.name}</p>
            <p style={{marginBottom: 0}}>{best.bests.one_rm}</p>
            <p style={{marginBottom: 0}}>{best.bests.velocity.mvt ? best.bests.velocity.mvt :  '-'}</p>
            <p
              style={{marginBottom: 0, cursor: best.bests.velocity?.intercept ? 'pointer' : 'default'}}
              onClick={() => {
                if (best.bests.velocity?.coefficients) {
                  openSlopeCurve(best);
                }
              }} 
            >{best.bests.velocity?.coefficients? <span>View curve <OpenInNew color="primary"/></span>  : "Curve not calculated" }</p>
          </div>
        ))}

      </div>
    </div>
    </div>
  );
}

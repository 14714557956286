import { AccountType, ExerciseCardClass } from '../../tsUtils';
import { createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { ClientInputsClass, ClientInputsInterface } from '../../tsUtils/clientInputsTypes';
import { selectPreviousSession } from '../sessions/reducers';

export interface ExerciseCardState extends EntityState<ExerciseCardClass> {
  loading: boolean;
  loaded: Boolean;
  error: any | null;
  currentClient: number;
  client_inputs: ClientInputsInterface[];
}

const exerciseCardAdapter = createEntityAdapter<ExerciseCardClass>({});

export const cardsInitialState: ExerciseCardState = exerciseCardAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  currentClient: 0,
  client_inputs: [],
});

export const exerciseCardSlice = createSlice({
  name: 'exercise_cards',
  initialState: cardsInitialState,
  reducers: {
    exercise_cardsLoading(state) {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    },
    exercise_cardsLoaded(state) {
      state.loading = false;
      state.loaded = true;
      state.error = null;
    },
    exercise_cardsFail(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
    getExerciseCardsSuccess(state, action) {
      exerciseCardAdapter.upsertMany(state, action.payload);
    },
    updateCurrentClient(state, action) {
      state.currentClient = action.payload;
    },
    createExerciseCardSuccess(state, action) {
      exerciseCardAdapter.upsertOne(state, action.payload);
    },
    deleteExerciseCardSuccess(state, action) {
      exerciseCardAdapter.removeOne(state, action.payload);
    },
    updateExerciseCardSuccess(state, action) {
      state.entities = {
        ...state.entities,
        [action.payload.id]: action.payload,
      };
    },
    updateExerciseCardExerciseSuccess(state, action) {
      const card = state.entities[action.payload.cardId];
      if (card) {
        card.exercise = action.payload.exercise;
        exerciseCardAdapter.upsertOne(state, card);
      }
    },
    getClientInputsSuccess(state, action) {
      state.client_inputs = action.payload;
    },
    upsertClientInputs(state, action) {
      for (const inputs of action.payload) {
        const index = state.client_inputs.findIndex(input => input.id === inputs.id);
        if (index >= 0) {
          state.client_inputs[index] = inputs;
        } else {
          state.client_inputs.push(inputs);
        }
      }
    },
    updateClientInputsSuccess(state, action) {
      const index = state.client_inputs.findIndex(inputs => inputs.id === action.payload.id);
      if (index >= 0) {
        state.client_inputs[index] = action.payload;
      }
    },
  },
});
export const selectCardsBySelectedSession = createSelector(
  [
    (state: RootState) => exerciseCardSelectors.selectAll(state),
    (state: RootState) => state.sessions.selectedId,
  ],
  (allCards, session_id) => {
    if (!!allCards && !!session_id) {
      return allCards.reduce((acc, card) => {
        if (card.session === session_id) {
          acc.set(card.id, card);
        }
        return acc;
      }, new Map());
    }
    return new Map();
  },
);

export const selectClientInputs = createSelector(
  [
    (state: RootState) => state.exercise_cards.client_inputs,
    (state: RootState) => state.exercise_cards.currentClient,
    (state: RootState) => state.auth.user,
    (state: RootState, cardId: number,) => cardId,
    
  ],
  (client_inputs,userId, user, cardId) => {
    if (user?.account_type == AccountType.Client) return client_inputs?.find(inputs => inputs.card === cardId && inputs.client === user.id);
    
    return client_inputs?.find(inputs => inputs.card === cardId && inputs.client === userId);
  },
);

export const selectPreviousClientInputs = createSelector([
  (state: RootState) => state.exercise_cards.client_inputs,
  (state: RootState) => selectPreviousSession(state),
  (state: RootState) => state.exercise_cards.currentClient,
  (state: RootState, cardIndex: number) => cardIndex,
], (client_inputs, previousSession, userId, cardIndex) => {
  if (!previousSession) return null;
  const previousCardId = previousSession.exercise_cards[cardIndex].id;
  if (!previousCardId) return null;
  return client_inputs?.find(inputs => inputs.card === previousCardId && inputs.client === userId) || null;

});

export const {
  createExerciseCardSuccess,
  deleteExerciseCardSuccess,
  exercise_cardsFail,
  exercise_cardsLoaded,
  exercise_cardsLoading,
  getClientInputsSuccess,
  getExerciseCardsSuccess,
  updateClientInputsSuccess,
  updateCurrentClient,
  updateExerciseCardSuccess,
  upsertClientInputs,
} = exerciseCardSlice.actions;

export const exerciseCardSelectors = exerciseCardAdapter.getSelectors<RootState>(
  state => state.exercise_cards,
);

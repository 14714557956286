import React, { Suspense, useEffect, useState } from 'react';
import '../style/App.scss';
import CoachTopBar from '../common/CoachTopBar';
import { useDispatch, useSelector } from 'react-redux';
import { getDefinitions } from '../+store/definitions/inputs/actionTypes';
import { programActions } from '../+store/programs/actionTypes';
import { Link, Navigate, Outlet } from 'react-router-dom';
import { toggleSidebar } from '../store/app/actions';
import { RootState } from '../store/store';
import { CircularProgress, ClickAwayListener, Dialog,  Button, DialogContent, IconButton } from '@mui/material';
import '../style/Unauth/unauthMain.scss';
import { AccountType } from '../tsUtils';
import { closePreviousInputs } from '../store/app/actions';
import PreviousInputs from './program/dialogs/PreviousInputs';
import CloseIcon from '@mui/icons-material/Close';
import { createPortal } from 'react-dom';
import Calculator from '../common/Calculator';
import Saved from '../common/Saved';
function ClientApp(props: any) {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.auth.user);
  const loaded = useSelector((state: RootState) => state.auth.loaded);
  const sideBar = useSelector((state: RootState) => state.app.sideBar);
  const showPreviousInputs = useSelector((state: RootState) => state.app.showPreviousInputs)
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  useEffect(() => {
    
    dispatch(programActions.clientGetPrograms(''));
  }, [dispatch]);

  function closeInputs() {
    dispatch(closePreviousInputs());
  }

  const topBarMenu = [
    {
      link: '/client/programs',
      icon: 'bi bi-clipboard',
      title: 'Programs',
    },
  ];

  return loaded ? (
    user && user?.account_type === AccountType.Client ? (
      <div className="layoutMainClient">
        <header className="layoutHeaderClient">
          <CoachTopBar
            setSideBar={() => dispatch(toggleSidebar())}
            menu1={topBarMenu}
            user={user}
          />
        </header>
        <main style={{ gridColumn: '1/3' }} className="main">
          <Outlet />
          
          <Dialog fullWidth={isMobile} open={showPreviousInputs != null} onClose={() => dispatch(closePreviousInputs())}> 
          
            <DialogContent sx={{padding: "10px"}}>
            <IconButton color="info" size='small' sx={{left: 'calc(100% - 25px)', top: '-10px'}} onClick={closeInputs}><CloseIcon /></IconButton>
            {showPreviousInputs && (
              <Suspense fallback={<div>Loading...</div>}>
                
                <PreviousInputs userId={showPreviousInputs.userId} index={showPreviousInputs.index} />
              </Suspense>
            )}
            </DialogContent>
          </Dialog>
          {createPortal(<Calculator />, document.getElementById('calculator') as HTMLElement)} 
        </main>
      </div>
    ) : user ? (
      <Navigate to="/coach/schedule" />
    ) : (
      <Navigate to="/" />
    )
  ) : (
    <div className="unauthmain">
      <CircularProgress />
    </div>
  );
}

export default ClientApp;

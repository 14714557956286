// Workouts

import { add, get } from 'lodash';
import { WorkoutPromptQuestions } from '../../coachApp/programs/dialogs/WorkoutDialog';
import {
  CooldownInterface,
  PostWorkoutInterface,
  SessionQuestion,
  WarmupInterface,
  WorkoutBaseInterface,
  WorkoutInterface,
} from '../../tsUtils';
import { addWarmupToWorkout } from './reducers';

export const actionTypes = {
  // List Workout
  GET_WORKOUTS: '[Workouts] Get Workout',
  GET_WORKOUTS_SUCCESS: '[Workouts] Get Workout Success',
  GET_WORKOUTS_FAIL: '[Workouts] Get Workout Failure',
  // Get Single Workout
  GET_PROGRAM_WORKOUTS: '[Workouts] Get Program Workouts',
  GET_PROGRAM_WORKOUTS_SUCCESS: '[Workouts] Get Program Workouts Success',
  GET_PROGRAM_WORKOUTS_FAIL: '[Workouts] Get Program Workouts Failure',
  // Update Workout
  UPDATE_WORKOUT_REQUEST: '[Workouts] Update Workout Request',
  UPDATE_WORKOUT_SUCCESS: '[Workouts] Update Workout Success',
  UPDATE_WORKOUT_FAIL: '[Workouts] Update Workout Failure',

  UPDATE_WORKOUT_DETAILS_REQUEST: '[Workouts] Update Workout Details Request',
  UPDATE_WORKOUT_DETAILS_SUCCESS: '[Workouts] Update Workout Details Success',
  UPDATE_WORKOUT_DETAILS_FAIL: '[Workouts] Update Workout Details Failure',

  CREATE_WORKOUT_REQUEST: '[Workouts] Create Workout Request',
  CREATE_WORKOUT_SUCCESS: '[Workouts] Create Workout Success',
  CREATE_WORKOUT_FAIL: '[Workouts] Create Workout Failure',

  GENERATE_WORKOUT_REQUEST: '[Workouts] Generate Workout Request',
  GENERATE_WORKOUT_SUCCESS: '[Workouts] Generate Workout Success',
  GENERATE_WORKOUT_FAIL: '[Workouts] Generate Workout Failure',

  COPY_WORKOUT_REQUEST: '[Workouts] Copy Workout Request',
  COPY_WORKOUT_SUCCESS: '[Workouts] Copy Workout Success',
  COPY_WORKOUT_FAIL: '[Workouts] Copy Workout Failure',

  CLEAR_WORKOUT: '[Workouts] Clear Workout',
  CLEAR_WORKOUT_SUCCESS: '[Workouts] Clear Workout Success',
  CLEAR_WORKOUT_FAIL: '[Workouts] Clear Workout Failure',

  DELETE_WORKOUT_REQUEST: '[Workouts] Delete Workout Request',
  DELETE_WORKOUT_SUCCESS: '[Workouts] Delete Workout Success',
  DELETE_WORKOUT_FAIL: '[Workouts] Delete Workout Fail',

  RENAME_WORKOUT_REQUEST: '[Workouts] Rename Workout Request',
  RENAME_WORKOUT_SUCCESS: '[Workouts] Rename Workout Success',
  RENAME_WORKOUT_FAIL: '[Workouts] Rename Workout Fail',

  GET_WARMUPS_REQUEST: '[Workouts] Get Warmups Request',
  GET_WARMUPS_SUCCESS: '[Workouts] Get Warmups Success',
  GET_WARMUPS_FAIL: '[Workouts] Get Warmups Fail',

  GET_COOLDOWNS_REQUEST: '[Workouts] Get Cooldowns Request',
  GET_COOLDOWNS_SUCCESS: '[Workouts] Get Cooldowns Success',
  GET_COOLDOWNS_FAIL: '[Workouts] Get Cooldowns Fail',

  CREATE_WARMUP_REQUEST: '[Workouts] Create Warmup Request',
  CREATE_WARMUP_SUCCESS: '[Workouts] Create Warmup Success',
  CREATE_WARMUP_FAIL: '[Workouts] Create Warmup Fail',

  CREATE_COOLDOWN_REQUEST: '[Workouts] Create Cooldown Request',
  CREATE_COOLDOWN_SUCCESS: '[Workouts] Create Cooldown Success',
  CREATE_COOLDOWN_FAIL: '[Workouts] Create Cooldown Fail',

  ADD_WARMUPS_TO_WORKOUT: '[Workouts] Add Warmups To Workout',
  ADD_COOLDOWNS_TO_WORKOUT: '[Workouts] Add Cooldowns To Workout',

};

export const workoutActions = {
  getWorkouts: (query: string) => {
    return {
      type: actionTypes.GET_WORKOUTS,
      query,
    };
  },
  getProgramWorkouts: (id: number) => {
    return {
      type: actionTypes.GET_PROGRAM_WORKOUTS,
      id,
    };
  },
  updateWorkout: (workout: WorkoutInterface) => {
    return {
      type: actionTypes.UPDATE_WORKOUT_REQUEST,
      workout,
    };
  },
  updateWorkoutDetails: (workout: WorkoutInterface, questions: SessionQuestion[]) => {
    return {
      type: actionTypes.UPDATE_WORKOUT_DETAILS_REQUEST,
      workout,
      questions,
    };
  },
  createWorkout: (
    workout: PostWorkoutInterface,
    questions: SessionQuestion[],
    config: {
      day: Day;
      week: number;
      date_time: string;
    },
  ) => {
    return {
      type: actionTypes.CREATE_WORKOUT_REQUEST,
      workout,
      questions,
      config,
    };
  },
  generateWorkout: (
    workout: PostWorkoutInterface,
    questions: SessionQuestion[],
    config: {
      day: Day;
      week: number;
      date_time: string;
    },
    prompt: WorkoutPromptQuestions
  ) => {
    return {
      type: actionTypes.GENERATE_WORKOUT_REQUEST,
      workout,
      questions,
      config,
      prompt
    };
  },
  copyWorkout: (workout_id: number) => {
    return {
      type: actionTypes.COPY_WORKOUT_REQUEST,
      workout_id,
    };
  },
  deleteWorkout: (workout_id: number, programId: number) => {
    return {
      type: actionTypes.DELETE_WORKOUT_REQUEST,
      workout_id,
      programId,
    };
  },
  renameWorkout: (workout_id: number, name: string) => {
    return {
      type: actionTypes.RENAME_WORKOUT_REQUEST,
      workout_id,
      name,
    };
  },
  getWarmups: () => {
    return {
      type: actionTypes.GET_WARMUPS_REQUEST,
    };
  },
  getCooldowns: () => {
    return {
      type: actionTypes.GET_COOLDOWNS_REQUEST,
    };
  },
  createWarmup: (warmup: WarmupInterface, workout_id?: number) => {
    return {
      type: actionTypes.CREATE_WARMUP_REQUEST,
      warmup,
      workout_id,
    };
  },
  createCooldown: (cooldown: CooldownInterface, workout_id?: number) => {
    return {
      type: actionTypes.CREATE_COOLDOWN_REQUEST,
      cooldown,
      workout_id,
    };
  },
  addWarmupsToWorkout: (workout_id: number, warmups: WarmupInterface[]) => {
    return {
      type: actionTypes.ADD_WARMUPS_TO_WORKOUT,
      workout_id,
      warmups,
    };
  },
  addCooldownsToWorkout: (workout_id: number, cooldowns: CooldownInterface[]) => {
    return {
      type: actionTypes.ADD_COOLDOWNS_TO_WORKOUT,
      workout_id,
      cooldowns,
    };
  }
};

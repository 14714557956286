import {
  ExerciseCardClass,
  ProgramDetailInterface,
  SessionDetailInterface,
  SessionInterface,
  WorkoutDetailInterface,
  WorkoutInterface,
  ProgramInterface,
} from '../tsUtils';
import { ClientInputsInterface } from '../tsUtils/clientInputsTypes';
interface ReducedWorkouts {
  workouts: WorkoutInterface[];
  sessions: SessionInterface[];
  exercise_cards: ExerciseCardClass[];
  client_inputs: ClientInputsInterface[];
}
interface ReducedSession {
  updated: SessionInterface;
  exercise_cards: ExerciseCardClass[];
}
export function reduceWorkouts(array: WorkoutDetailInterface[]): ReducedWorkouts {
  const arrays = array.reduce(
    (obj, workout) => {
      const sessions = workout.sessions
        ? workout.sessions.map(session => {
            return {
              ...session,
              exercise_cards: session.exercise_cards
                ? session.exercise_cards
                    .map(s => {
                      return { id: s.id! };
                    })
                : [],
            };
          })
        : [];
      const exercise_cards = workout.sessions.reduce((cards, session) => {
        const sessionCards = session.exercise_cards ? session.exercise_cards : [];
        return [...cards, ...sessionCards];
      }, [] as ExerciseCardClass[]);
      const newWorkout: WorkoutInterface = {
        ...workout,
        sessions: workout.sessions
          .map(s => {
            return { id: s.id!, order: s.order };
          })
          .sort((a, b) => a.order - b.order),
      };
      obj.workouts.push(newWorkout);
      obj.sessions = [...obj.sessions, ...sessions];
      obj.exercise_cards = [...obj.exercise_cards, ...exercise_cards];
      return obj;
    },
    { workouts: [], sessions: [], exercise_cards: [], client_inputs: [] } as ReducedWorkouts,
  );
  return arrays;
}
export function reduceProgram(
  program: ProgramDetailInterface,
): ReducedWorkouts & { selectedProgram: ProgramInterface } {
  const selectedProgram = {
    ...program,
    workouts: program.workouts.map(wo => {
      return { id: wo.id, order: wo.order };
    }),
  };
  const arrays = program.workouts.reduce(
    (obj, workout) => {
      const exercise_cards:  ExerciseCardClass[] = [];
      const client_inputs: ClientInputsInterface[] = [];
      const sessions = workout.sessions
        ? workout.sessions.map(session => {
            return {
              ...session,
              exercise_cards: session.exercise_cards
                ? session.exercise_cards
                    .map(s => {
                      s.client_inputs?.forEach(c => {
                            client_inputs.push(c);
                          })
                      delete s.client_inputs;
                      exercise_cards.push(s);
                      return { id: s.id! };
                    })
                : [],
            };
          })
        : [];
      const newWorkout: WorkoutInterface = {
        ...workout,
        sessions: workout.sessions
          .map(s => {
            return { id: s.id!, order: s.order };
          })
          .sort((a, b) => a.order - b.order),
      };
      obj.workouts.push(newWorkout);
      obj.sessions = [...obj.sessions, ...sessions];
      obj.exercise_cards = [...obj.exercise_cards, ...exercise_cards];
      obj.client_inputs = [...obj.client_inputs, ...client_inputs];
      return obj;
    },
    { workouts: [], sessions: [], exercise_cards: [], client_inputs: [] } as ReducedWorkouts,
  );
  return { ...arrays, selectedProgram };
}

export function reduceSession(sess: SessionDetailInterface): ReducedSession {
  const exercise_cards = sess.exercise_cards ? [...sess.exercise_cards] : [];
  const updated = {
    ...sess,
    exercise_cards: sess.exercise_cards
      ? sess.exercise_cards
          .map((s) => {
            return { id: s.id! };
          })
      : [],
  };
  return { updated, exercise_cards };
}

import React, { Component } from "react";
import CalendarComponent from "./containers/CalendarComponent";
import '../../style/Coach/Schedule.scss';
import BaseDialog from "../programs/dialogs/BaseDialog";
import AddProgramEvents from "./forms/AddProgramEvents";
import { Add } from "@mui/icons-material";
function Schedule() {
  return (
    <div className="schedule-cont">
      <div style={{width: '100%', display: 'flex', height: '50px', justifyContent: 'flex-end', alignItems: "center"}}>
        <BaseDialog buttonText="Add Program" icon={<Add />} variant="text" buttonWidth={180}>
         <AddProgramEvents />
        </BaseDialog>
      </div>
      <CalendarComponent></CalendarComponent>
    </div>
  );
}

export default Schedule;

import { put, takeLatest, SagaReturnType } from 'redux-saga/effects';
import * as workoutAPI from '../../coachApp/services/workoutAPI';
// import { REFRESH_TOKEN_REQUEST, signInFail, signInSuccess, SIGN_IN_REQUEST, SIGN_IN_SUCCESS } from "../../.";
import { actionTypes } from './actionTypes';
import {
  workoutsLoading,
  workoutsFail,
  getWorkoutsSuccess,
  createWorkoutSuccess,
  updateWorkoutSuccess,
  deleteWorkoutSuccess,
  renameWorkoutSuccess,
  workoutsLoaded,
  selectWorkoutWithSessions,
  addWarmupToWorkout,
  addCooldownToWorkout,
  addWarmupsToWorkout,
  addCooldownsToWorkout,
} from './reducers';
import {
  addWorkoutSuccess,
  programSlice,
  removeProgramWorkout,
  updateProgramConfigSuccess,
} from '../programs/reducers';
import store from '../../store/store';
import * as types from './types';
import { reduceWorkouts } from '../../store/utility';
import { getSessionsSuccess, deleteWorkoutSessions } from '../sessions/reducers';
import { getExerciseCardsSuccess } from '../exerciseCards/reducers';
import { closeDialogs, saved } from '../../store/app/actions';
import { CooldownInterface, WarmupInterface } from '../../tsUtils';
import { SuccessResponse } from '../../tsUtils/responseType';

export function* getWorkoutsSaga({ query }: types.GetWorkouts) {
  try {
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof workoutAPI.getWorkouts> = yield workoutAPI.getWorkouts(
      query,
    );
    yield put(getWorkoutsSuccess(response));
    yield put(workoutsLoaded());
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}
export function* createWorkoutSaga({ workout, config, questions }: types.CreateWorkoutRequest) {
  try {
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof workoutAPI.createWorkout> =
      yield workoutAPI.createWorkout(workout, questions, config);
    const { workouts, sessions } = reduceWorkouts([response]);
    yield put(
      updateProgramConfigSuccess({
        day: config.day,
        week: config.week,
        session_config: {
          workout_id: workouts[0].id,
          session_id: sessions[0].id,
          date_time: config.date_time,
        },
      }),
    );

    yield put(createWorkoutSuccess(workouts[0]));

    yield put(getSessionsSuccess(sessions));

    yield put(workoutsLoaded());
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}
export function* generateWorkoutSaga({ workout, config, questions, prompt }: types.GenerateWorkoutRequest) {
  try {
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof workoutAPI.generateWorkout> =
      yield workoutAPI.generateWorkout(workout, questions, config, prompt);
    const { workouts, sessions, exercise_cards } = reduceWorkouts([response]);
    yield put(
      updateProgramConfigSuccess({
        day: config.day,
        week: config.week,
        session_config: {
          workout_id: workouts[0].id,
          session_id: sessions[0].id,
          date_time: config.date_time,
        },
      }),
    );

    yield put(createWorkoutSuccess(workouts[0]));

    yield put(getSessionsSuccess(sessions));

    yield put(getExerciseCardsSuccess(exercise_cards));

    yield put(workoutsLoaded());
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}
export function* updateWorkoutSaga({ workout }: types.UpdateWorkoutRequest) {
  try {
    // const response: SagaReturnType<typeof workoutAPI.updateWorkout> =
    //   yield workoutAPI.updateWorkout(workout);
    yield put(
      updateWorkoutSuccess({
        id: workout.id!,
        changes: { ...workout },
      }),
    );
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}
export function* updateWorkoutDetailsSaga({
  workout,
  questions,
}: types.UpdateWorkoutDetailsRequest) {
  try {
    const response: SagaReturnType<typeof workoutAPI.updateWorkout> =
      yield workoutAPI.updateWorkout(workout, questions);
    const { workouts, sessions } = reduceWorkouts([response]);
    yield put(getSessionsSuccess(sessions));
    yield put(
      updateWorkoutSuccess({
        id: workout.id!,
        changes: { ...workouts[0] },
      }),
    );
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}
export function* renameWorkoutSaga({ workout_id, name }: types.RenameWorkoutRequest) {
  try {
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof workoutAPI.renameWorkout> =
      yield workoutAPI.renameWorkout(workout_id, name);
    yield put(renameWorkoutSuccess(response));
    yield put(workoutsLoaded());
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}
export function* deleteWorkoutSaga({ workout_id, programId }: types.DeleteWorkoutRequest) {
  try {
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof workoutAPI.deleteWorkout> =
      yield workoutAPI.deleteWorkout(workout_id);
    yield put(deleteWorkoutSuccess(workout_id));
    yield put(removeProgramWorkout({ workout_id, programId }));
    yield put(deleteWorkoutSessions(workout_id));
    yield put(workoutsLoaded());
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}
export function* copyWorkoutSaga({ workout_id }: types.CopyWorkoutRequest) {
  try {
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof workoutAPI.copyWorkout> = yield workoutAPI.copyWorkout(
      workout_id,
    );
    const { workouts, sessions, exercise_cards } = reduceWorkouts([response]);
    yield put(
      addWorkoutSuccess({
        programId: workouts[0]?.program,
        workout: {
          id: workouts[0].id,
          order: workouts[0].order,
        },
      }),
    );
    yield put(createWorkoutSuccess(workouts[0]));
    yield put(getSessionsSuccess(sessions));
    yield put(getExerciseCardsSuccess(exercise_cards));
    yield put(workoutsLoaded());
  } catch (error: any) {
    yield console.log(error);
    yield put(workoutsFail(error));
  }
}

export function* createWarmupSaga({ warmup, workout_id }: types.CreateWarmupRequest) {
  try {
    yield put(workoutsLoading());
    delete warmup.id;
    const response: SagaReturnType<typeof workoutAPI.createWarmup> = yield workoutAPI.createWarmup(
      warmup,
      workout_id,
    );
    if(response.ok) {
      const data: SuccessResponse<WarmupInterface> = yield response.json();
      if(workout_id) {
        
        yield put(addWarmupToWorkout({ workout_id, warmup: data.data! }));
      }
      yield put(closeDialogs())
      yield put(saved(data.message));
      yield put(workoutsLoaded());
    }
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}

export function* createCooldownSaga({ cooldown, workout_id }: types.CreateCooldownRequest) {
  try {
    yield put(workoutsLoading());
    delete cooldown.id;
    const response: SagaReturnType<typeof workoutAPI.createCooldown> = yield workoutAPI.createCooldown(
      cooldown,
      workout_id
    );
    if(response.ok) {
      const data: SuccessResponse<CooldownInterface> = yield response.json();
      if(workout_id) {
        yield put(addCooldownToWorkout({ workout_id, cooldown: data.data! }));
      }
      yield put(closeDialogs())
      yield put(saved(data.message));
      yield put(workoutsLoaded());
    }
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}

export function* addWarmupsToWorkoutSaga({ workout_id, warmups }: types.AddWarmupsToWorkout) {
  try {
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof workoutAPI.addWarmupToWorkout> =
      yield workoutAPI.addWarmupsToWorkout(workout_id, warmups.map(warmup => warmup.id!));
    if(response.ok) {
      const data: SuccessResponse<WarmupInterface> = yield response.json();
      yield put(addWarmupsToWorkout({ workout_id, warmups }));
      yield put(saved(data.message));
      yield put(workoutsLoaded());
    }
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}

export function* addCooldownsToWorkoutSaga({ workout_id, cooldowns }: types.AddCooldownsToWorkout) {
  try {
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof workoutAPI.addCooldownToWorkout> =
      yield workoutAPI.addCooldownsToWorkout(workout_id, cooldowns.map(cooldown => cooldown.id!));
    if(response.ok) {
      const data: SuccessResponse<CooldownInterface> = yield response.json();
      yield put(addCooldownsToWorkout({ workout_id, cooldowns }));
      yield put(saved(data.message));
      yield put(workoutsLoaded());
    }
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}

export function* workoutSaga() {
  yield takeLatest(actionTypes.GET_WORKOUTS, getWorkoutsSaga);
  yield takeLatest(actionTypes.CREATE_WORKOUT_REQUEST, createWorkoutSaga);
  yield takeLatest(actionTypes.UPDATE_WORKOUT_REQUEST, updateWorkoutSaga);
  yield takeLatest(actionTypes.UPDATE_WORKOUT_DETAILS_REQUEST, updateWorkoutDetailsSaga);
  yield takeLatest(actionTypes.DELETE_WORKOUT_REQUEST, deleteWorkoutSaga);
  yield takeLatest(actionTypes.COPY_WORKOUT_REQUEST, copyWorkoutSaga);
  yield takeLatest(actionTypes.RENAME_WORKOUT_REQUEST, renameWorkoutSaga);
  yield takeLatest(actionTypes.GENERATE_WORKOUT_REQUEST, generateWorkoutSaga);
  yield takeLatest(actionTypes.CREATE_WARMUP_REQUEST, createWarmupSaga);
  yield takeLatest(actionTypes.CREATE_COOLDOWN_REQUEST, createCooldownSaga);
  yield takeLatest(actionTypes.ADD_WARMUPS_TO_WORKOUT, addWarmupsToWorkoutSaga);
  yield takeLatest(actionTypes.ADD_COOLDOWNS_TO_WORKOUT, addCooldownsToWorkoutSaga);
  // yield takeLatest(REFRESH_TOKEN_REQUEST, doRefreshToken);
}

import { useState } from 'react';
import { InputGroup, ExerciseCardClass } from '../../tsUtils/exerciseCardTypes';
import { InitialTotals } from '../../tsUtils/defaults';
import { Total, CardTotals, TotalValueUnits} from '../../tsUtils/totalsTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Definition } from '../../tsUtils';
import { Dictionary } from '@reduxjs/toolkit';

export interface CardTotalsInput {
  card: ExerciseCardClass;
  inputs: Record<string, string[]>;
}

export const CalculateTotal = (inputs: Array<number[]>): number => {
  return inputs[0].reduce((sum, _, i) => {
    const product = inputs.reduce((prod, arr) => prod * (Number(arr[i]) || 0), 1);
    return sum + product;
  }, 0);
};

class ValuesGetter {
  rest: number;
  tempo: number;
  inputValues: { [key: string]: number[] };
  sets: number;

  constructor(rest: string | null, tempo: string, inputs: InputGroup, input_types: Dictionary<Definition>) {
    this.rest = this.calculateRest(rest);
    this.tempo = this.calculateTempo(tempo);
    this.sets = 0;
    this.inputValues = this.extractInputValues(inputs, input_types);
  }

  private calculateRest(rest: string | null): number {
    return rest?.split(':').reduce((acc, interval, i) => acc + Number(interval) * Math.pow(60, 2 - i), 0) || 0;
  }

  private calculateTempo(tempo: string): number {
    return tempo.split('/').reduce((acc, val) => acc + (parseInt(val) || 0), 0);
  }

  private extractInputValues(inputs: InputGroup, input_types: Dictionary<Definition>): { [key: string]: number[] } {
    return inputs.reduce((acc, {key, value}) => {
      if (value.length > this.sets) this.sets = value.length;
      const input_type = input_types[key];
      if (input_type) {
        acc[input_type.name] = value.map(val => Number(val));
      }
      return acc;
    }, {} as { [key: string]: number[] });
  }
}

export const useCardTotals = (): [CardTotals, (card: ExerciseCardClass, input: InputGroup) => void, boolean] => {
  const [totals, setTotals] = useState<CardTotals>(InitialTotals);
  const input_types = useSelector((state: RootState) => state.definitions.inputs.entities);
  const [loading, setLoading] = useState<boolean>(true);

  const updateTotals = ({config:{ rest, tempo }}: ExerciseCardClass, updatedInputs: InputGroup): void => {
    if (!updatedInputs || !input_types) return;

    setLoading(true);
    const valuesGetter = new ValuesGetter(rest, tempo, updatedInputs, input_types);
    const inputs = valuesGetter.inputValues;

    setTotals({
      [Total.RVOL]: inputs.Reps ? CalculateTotal([inputs.Reps]) + '' : '',
      [Total.LVOL]: inputs.Reps && inputs.Weight ? CalculateTotal([inputs.Reps, inputs.Weight]) + TotalValueUnits[Total.LVOL] : '',
      [Total.TUT]: inputs.Reps ? CalculateTotal([inputs.Reps]) * valuesGetter.tempo + TotalValueUnits[Total.TUT] : '',
      [Total.TIME]: inputs.Time ? CalculateTotal([inputs.Time]) + TotalValueUnits[Total.TIME] : '',
      [Total.SETS]: valuesGetter.sets + '',
      [Total.REST]: valuesGetter.rest * (valuesGetter.sets - 1) + TotalValueUnits[Total.REST],
      [Total.LENGTH]: inputs.Reps ? CalculateTotal([inputs.Reps]) * valuesGetter.tempo + valuesGetter.rest * (valuesGetter.sets - 1) + TotalValueUnits[Total.LENGTH] : '',
      [Total.ONERM]: '',
    });

    setLoading(false);
  };

  return [totals, updateTotals, loading];
};
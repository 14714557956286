import { call, put, takeLatest, SagaReturnType } from 'redux-saga/effects';
import * as templateAPI from '../../coachApp/services/templateAPI';

// import { REFRESH_TOKEN_REQUEST, signInFail, signInSuccess, SIGN_IN_REQUEST, SIGN_IN_SUCCESS } from "../../.";
import { templateActions, TemplateActionTypes } from './actionTypes';
import { getTemplatesSuccess, removeTemplateSuccess, templatesFail, templatesLoaded, templatesLoading } from './reducers';
import { getProgramsSuccess } from '../programs/reducers';

export function* getTemplatesSaga({
  query,
}: ReturnType<typeof templateActions.getTemplatesRequest>) {
  try {
    yield put(templatesLoading());
    const response: SagaReturnType<typeof templateAPI.getTemplates> =
      yield templateAPI.getTemplates(query);
    yield put(getTemplatesSuccess(response));
    yield put(templatesLoaded());
  } catch (error: any) {
    yield put(templatesFail(error));
  }
}

export function* createProgramFromTemplateSaga({
  templateId,
  data,
}: ReturnType<typeof templateActions.createProgramFromTemplateRequest>) {
  try {
    yield put(templatesLoading());
    const response: SagaReturnType<typeof templateAPI.createProgramFromTemplate> =
      yield templateAPI.createProgramFromTemplate(templateId, data);
    yield put(getProgramsSuccess([response]));
    yield put(templatesLoaded());
  } catch (error: any) {
    yield put(templatesFail(error));
  }
}

export function* deleteTemplateSaga({
  payload,
}: ReturnType<typeof templateActions.deleteTemplateRequest>) {
  try {
    yield put(templatesLoading());
    const response: SagaReturnType<typeof templateAPI.removeTemplate> =
      yield templateAPI.removeTemplate(payload);
    yield put(removeTemplateSuccess(payload));
    yield put(templatesLoaded());
  } catch (error: any) {
    yield put(templatesFail(error));
  }
}

export function* templateSaga() {
  yield takeLatest(TemplateActionTypes.GetTemplatesRequest, getTemplatesSaga);
  yield takeLatest(
    TemplateActionTypes.CreateProgramFromTemplateRequest,
    createProgramFromTemplateSaga,
  );
  yield takeLatest(TemplateActionTypes.DeleteTemplateRequest, deleteTemplateSaga);
  // yield takeLatest(REFRESH_TOKEN_REQUEST, doRefreshToken);
}

import { ClientInputsInterface } from './clientInputsTypes';

export type InputGroup = {key: number, value: string[]}[];

export interface WorkoutCardBase {
  id: number;
  card_type: string;
  comment: string;
  workout: number;
  session: number;
  program: number;
  exercise?: { id: number; name: string; };
  user_exercise?: { id: number; name: string; };
  auto_prescribe: boolean;
}

export class ExerciseCardConfig {
  public card_no: string = 'A1';
  public variation_1: string = '';
  public variation_2: string = '';
  public equipment: string = '';
  public rest: string = '00:00:00';
  public tempo: string = '2/0/1/0';
  public adjust_max: string = '1';
  public inputs: InputGroup = [
    {key: 6, value: ['']} ,
    {key: 1, value: ['']},
  ];
  constructor(partial?: Partial<ExerciseCardConfig>) {
    Object.assign(this, partial);
  }
}

export class ExerciseCardClass  {
  public config: ExerciseCardConfig = new ExerciseCardConfig();
  public comment: string | null = '';
  public session: number | null = null;
  public workout: number | null = null;
  public program: number | null = null;
  public id?: number;
  public exercise?: { id: number; name: string; };
  public user_exercise?: { id: number; name: string; };
  public client_inputs?: ClientInputsInterface[];
  public auto_prescribe: boolean = false;
  constructor(partial?: Partial<ExerciseCardClass>) {
    Object.assign(this, partial);
  }
}

import { ExerciseCardClass, InputGroup } from '.';
import { Total } from './totalsTypes';

export function newCard(card: ExerciseCardClass) {}
export function newSet(inputs: InputGroup): InputGroup {
  return [...inputs.map(({key, value}) => {
    return { key, value: [...value, value[value.length - 1]] };
  })];
}

export const InitialTotals = {
  [Total.RVOL]: '',
  [Total.LVOL]: '',
  [Total.TUT]: '',
  [Total.TIME]: '',
  [Total.SETS]: '',
  [Total.REST]: '',
  [Total.LENGTH]: '',
  [Total.ONERM]: '',
};
import { put, takeLatest, SagaReturnType } from 'redux-saga/effects';
import { reduceSession } from '../../store/utility';
import * as sessionAPI from '../../coachApp/services/sessionAPI';
import * as workoutAPI from '../../coachApp/services/workoutAPI';
import { getExerciseCardsSuccess, upsertClientInputs } from '../exerciseCards/reducers';
import { updateSessionFeedback } from '../../clientApp/services/clientInputsApi';
import {
  removeProgramConfigSessionSuccess,
  updateProgramConfigSuccess,
} from '../programs/reducers';
import { addSessionSuccess, removeWorkoutSession } from '../workouts/reducers';
// import { REFRESH_TOKEN_REQUEST, signInFail, signInSuccess, SIGN_IN_REQUEST, SIGN_IN_SUCCESS } from "../../.";
import { actionTypes } from './actionTypes';
import {
  sessionsLoading,
  sessionsFail,
  getSessionsSuccess,
  createSessionSuccess,
  updateSessionSuccess,
  deleteSessionSuccess,
  sessionsLoaded,
  renameSessionSuccess,
  updateSessionFeedbackSuccess,
  updateSessionConfigSuccess,
} from './reducers';
import * as types from './types';
import { SuccessResponse } from '../../tsUtils/responseType';
import { ExerciseCardClass, SessionConfig } from '../../tsUtils';
import { closeDialogs, saved } from '../../store/app/actions';
import exp from 'constants';
import { ClientInputsInterface } from '../../tsUtils/clientInputsTypes';

export function* getSessionsSaga({ query }: types.GetSessions) {
  try {
    yield put(sessionsLoading());
    const response: SagaReturnType<typeof sessionAPI.getSessions> = yield sessionAPI.getSessions(
      query,
    );
    yield put(getSessionsSuccess(response));
  } catch (error: any) {
    yield put(sessionsFail(error));
  }
}
export function* createSessionSaga({ session }: types.CreateSessionRequest) {
  try {
    yield put(sessionsLoading());
    const response: SagaReturnType<typeof sessionAPI.createSession> =
      yield sessionAPI.createSession(session);
    yield put(createSessionSuccess(response));
    yield put(sessionsLoaded());
  } catch (error: any) {
    yield put(sessionsFail(error));
  }
}
export function* updateSessionSaga({ session }: types.UpdateSessionRequest) {
  try {
    // const response: SagaReturnType<typeof sessionAPI.updateSession> =
    //   yield sessionAPI.updateSession(session);
    // const { updated, exercise_cards } = reduceSession(response);
    yield put(updateSessionSuccess(session));
    // yield put(getExerciseCardsSuccess(exercise_cards));
  } catch (error: any) {
    yield console.log(error);
    yield put(sessionsFail(error));
  }
}
export function* updateSessionFeedbackSaga({ data }: types.UpdateSessionFeedbackRequest) {
  try {
    const response: SagaReturnType<typeof updateSessionFeedback> = yield updateSessionFeedback(
      data,
    );
    yield put(updateSessionFeedbackSuccess(response));
  } catch (error: any) {
    yield console.log(error);
    yield put(sessionsFail(error));
  }
}
export function* repeatWorkoutSaga({ data }: types.RepeatWorkoutRequest) {
  try {
    const response: SagaReturnType<typeof sessionAPI.copySession> = yield workoutAPI.repeatWorkout(
      data,
    );
    const { updated, exercise_cards } = reduceSession(response);
    yield put(createSessionSuccess(updated));
    if (exercise_cards) {
      yield put(getExerciseCardsSuccess(exercise_cards));
    }
    yield put(
      updateProgramConfigSuccess({
        day: data.day,
        week: data.week,
        session_config: {
          workout_id: data.workout,
          session_id: updated.id,
          date_time: data.date_time,
        },
      }),
    );
    yield put(addSessionSuccess(updated));
  } catch (error: any) {
    yield console.log(error);
    yield put(sessionsFail(error));
  }
}
export function* copySessionSaga({ session_id }: types.CopySessionRequest) {
  try {
    const response: SagaReturnType<typeof sessionAPI.copySession> = yield sessionAPI.copySession(
      session_id,
    );
    const { updated, exercise_cards } = reduceSession(response);
    yield put(createSessionSuccess(updated));
    if (exercise_cards) {
      yield put(getExerciseCardsSuccess(exercise_cards));
    }

    yield put(addSessionSuccess(updated));
  } catch (error: any) {
    yield console.log(error);
    yield put(sessionsFail(error));
  }
}
export function* renameSessionSaga({ session_id, name }: types.RenameSessionRequest) {
  try {
    yield put(sessionsLoading());
    const response: SagaReturnType<typeof sessionAPI.renameSession> =
      yield sessionAPI.renameSession(session_id, name);
    yield put(renameSessionSuccess(response));
    yield put(sessionsLoaded());
  } catch (error: any) {
    yield put(sessionsFail(error));
  }
}
export function* deleteSessionSaga({ day, week, config }: types.DeleteSessionRequest) {
  try {
    yield put(sessionsLoading());
    yield console.log({ day, week, config });
    const response: SagaReturnType<typeof sessionAPI.deleteSession> =
      yield sessionAPI.deleteSession(config.session_id, day, week, config);
    yield put(removeProgramConfigSessionSuccess({ day, week, session_id: config.session_id }));
    yield put(deleteSessionSuccess(config.session_id));
    yield put(removeWorkoutSession({ id: config.session_id, workout_id: config.workout_id }));
  } catch (error: any) {
    yield put(sessionsFail(error));
  }
}
export function* renameSessionSectionSaga({
  session_id,
  section_index,
  name,
}: types.RenameSessionSection) {
  try {
    yield put(sessionsLoading());
    const response: SagaReturnType<typeof sessionAPI.renameSessionSection> =
      yield sessionAPI.renameSessionSection(session_id, section_index, name);
    if (response.ok) {
      const data: SuccessResponse<SessionConfig> = yield response.json();
      yield put(updateSessionConfigSuccess({id: session_id, config: data.data!}));
      yield put(saved(data.message))
      yield put(sessionsLoaded());

    }
  } catch (error: any) {
    yield put(sessionsFail(error));
  }
}
export function* addSessionSectionSaga({
  session_id,
  section_index,
  sections,
}: types.AddSessionSection) {
  try {
    yield put(sessionsLoading());
    const response: SagaReturnType<typeof sessionAPI.addSessionSection> =
      yield sessionAPI.addSessionSection(session_id, section_index !== undefined ? {section_index} : { sections } as any);
    if (response.ok) {
      const data: SuccessResponse<{config: SessionConfig, cards: ExerciseCardClass[]}> = yield response.json();
      yield put(updateSessionConfigSuccess({id: session_id, config: data.data?.config!}));
      const client_inputs = data.data?.cards!.reduce((a, c) => {
        if (c.client_inputs) {
          return [...a, ...c.client_inputs];
        }
        return a;
      },[] as ClientInputsInterface[]);
      yield put(getExerciseCardsSuccess(data.data?.cards!));
      yield put(upsertClientInputs(client_inputs));
      yield put(saved(data.message))
      yield put(closeDialogs());
      yield put(sessionsLoaded());
    }
  } catch (error: any) {
    yield put(sessionsFail(error));
  }
}
export function* deleteSessionSectionSaga({
  session_id,
  section_index,
}: types.DeleteSessionSection) {
  try {
    yield put(sessionsLoading());
    const response: SagaReturnType<typeof sessionAPI.deleteSessionSection> =
      yield sessionAPI.deleteSessionSection(session_id, section_index);
    if (response.ok) {
      const data: SuccessResponse<SessionConfig> = yield response.json();
      yield put(updateSessionConfigSuccess({id: session_id, config: data.data!}));
      yield put(saved(data.message))
      yield put(sessionsLoaded());
    }
  } catch (error: any) {
    yield put(sessionsFail(error));
  }
}

export function* sessionSaga() {
  yield takeLatest(actionTypes.GET_SESSIONS, getSessionsSaga);
  yield takeLatest(actionTypes.CREATE_SESSION_REQUEST, createSessionSaga);
  yield takeLatest(actionTypes.UPDATE_SESSION_REQUEST, updateSessionSaga);
  yield takeLatest(actionTypes.DELETE_SESSION_REQUEST, deleteSessionSaga);
  yield takeLatest(actionTypes.RENAME_SESSION_REQUEST, renameSessionSaga);
  yield takeLatest(actionTypes.COPY_SESSION_REQUEST, copySessionSaga);
  yield takeLatest(actionTypes.REPEAT_WORKOUT_REQUEST, repeatWorkoutSaga);
  yield takeLatest(actionTypes.UPDATE_SESSION_FEEDBACK_REQUEST, updateSessionFeedbackSaga);
  yield takeLatest(actionTypes.RENAME_SESSION_SECTION, renameSessionSectionSaga);
  yield takeLatest(actionTypes.ADD_SESSION_SECTION, addSessionSectionSaga);
  yield takeLatest(actionTypes.DELETE_SESSION_SECTION, deleteSessionSectionSaga);
}


import { ExerciseCardClass,  } from './exerciseCardTypes';

export type SessionConfig = {name: string, ids: number[]}[];

export interface SessionBaseInterface {
  workout: number;
  name: string;
  order: number;
  created: Date;
  modified: Date;
  questions: SessionQuestion[];
  id: number;
}
export class SessionQuestion {
  question: string = '';
  type: 'string' | 'number' | 'boolean' | 'scale' = 'string';
  constructor(partial?: SessionQuestion) {
    Object.assign(this, partial);
  }
}

export interface SessionFeedbackRequest {
  session_id: number;
  feedback: string[];
  comment: string;
}
export interface SessionInterface extends SessionBaseInterface {
  config: SessionConfig;
  exercise_cards: { id: number; }[];
}
export class SessionClass<SessionInterface> {
  constructor(partial: SessionInterface) {
    Object.assign(this, partial);
  }
}
export interface SessionDetailInterface extends SessionBaseInterface {
  config: SessionConfig;
  exercise_cards: ExerciseCardClass[];
}
export class SessionDetail<SessionDetailInterface> {
  constructor(partial: SessionDetailInterface) {
    Object.assign(this, partial);
  }
}
export interface SessionSectionTemplate {
  id: number;
  name: string;
  creator: number;
  exercise_cards: { id: number; exercise: {id: number; name: string} }[];
}

import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import * as appActions from '../../../store/app/actions';

interface Props {
  title?: string;
  children: JSX.Element;
  buttonText: string | JSX.Element;
  buttonSize?: 'small' | 'medium' | 'large';
  buttonWidth: number;
  buttonColour?: any;
  fab?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  icon?: JSX.Element;
  // buttonVariant: string;
}

function BaseDialog(props: Props) {
  const [open, setOpen] = useState(false);
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  const close = useSelector((state: RootState) => state.app.closeDialogs);
  const dispatch = useDispatch();
  const [colour, setColour] = useState(props.buttonColour ? props.buttonColour : 'primary');
  const handleClickOpen = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setOpen(true);
  };

  const buttonSize = props.buttonSize ? props.buttonSize : 'large';

  useEffect(() => {
    if (close) {
      setOpen(false);
    }
    return function cleanup() {
      dispatch(appActions.closeDialogsSuccess());
    };
  }, [close, dispatch]);
  return (
    <div>
      {props.fab ? (
        <IconButton size={buttonSize} color={colour} onClick={event => handleClickOpen(event)}>
          {props.buttonText}
        </IconButton>
      ) : (
        <Button
          variant={props.variant ?? 'contained'}
          size={buttonSize}
          color={colour}
          onClick={event => handleClickOpen(event)}
          sx={{ width: props.buttonWidth }}
        >
          {props.icon} {props.buttonText}
        </Button>
      )}

      <Dialog fullScreen={isMobile} onBackdropClick={() => dispatch(appActions.closeDialogs())} open={open}>
        {props.title && <DialogTitle>{props.title}</DialogTitle>}
        <DialogContent>{props.children}</DialogContent>
      </Dialog>
    </div>
  );
}

export default BaseDialog;

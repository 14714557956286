import moment from "moment";
import { User, SessionDetailInterface, SessionInterface } from "../../tsUtils";
import * as paths from "./paths";
import { getAuthHeader } from "./paths";

export async function getSessions(
  query: string
): Promise<SessionDetailInterface[]> {
  let endpoint = `${paths.COACHSESSION}${query}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: "GET",
    headers,
  }).then(
    (response) => {
      if (response.ok) {
        return response.json();
      }
    },
    (error) => {
      return { error };
    }
  );
}
export async function createSession(session: SessionInterface): Promise<SessionDetailInterface> {
  let endpoint = `${paths.COACHSESSION}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify(session),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function updateSession(session: SessionInterface): Promise<SessionDetailInterface> {
  let endpoint = `${paths.COACHSESSION}/${session.id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(session),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      console.log(error);
      return { error };
    },
  );
}

export async function renameSession(session_id: number, name: string): Promise<SessionInterface> {
  let endpoint = `${paths.COACHSESSION}/${session_id}/rename`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(name),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function copySession(id: number): Promise<SessionDetailInterface> {
  let endpoint = `${paths.COACHSESSION}/${id}/copy`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}

export async function deleteSession(
  id: number,
  day: Day,
  week: number,
  config: {
    workout_id: number;
    session_id: number;
    date_time: string;
  },
): Promise<string> {
  let endpoint = `${paths.COACHSESSION}/${id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'DELETE',
    headers,
    body: JSON.stringify({ day, week, config }),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}

export async function renameSessionSection(
  session_id: number,
  section_index: number,
  name: string,
): Promise<Response> {
  let endpoint = `${paths.COACHSESSION}/${session_id}/rename_section`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify({ section_index, name }),
  })
}

export async function addSessionSection(
  session_id: number,
  data: {sections: {section_index: number, id: number }[]} | {section_index: number}
): Promise<Response> {
  let endpoint = `${paths.COACHSESSION}/${session_id}/add_section`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data),
  })
}

export async function deleteSessionSection(
  session_id: number,
  section_index: number,
): Promise<Response> {
  let endpoint = `${paths.COACHSESSION}/${session_id}/remove_section`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify({ section_index }),
  })
}

export async function saveSectionTemplate(
  name: string, 
  ids: number[]
): Promise<Response> {
  let endpoint = `${paths.SECTION_TEMPLATE}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify({ name, ids }),
  })
}

export async function getSectionTemplates(): Promise<Response> {
  let endpoint = `${paths.SECTION_TEMPLATE}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  })
}
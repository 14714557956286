import { Props } from 'html-react-parser/lib/attributes-to-props';
import * as actions from './actions';
import * as React from 'react';
export interface AppState {
  closeDialogs: boolean;
  sideBar: boolean;
  isMobile: boolean;
  showPreviousInputs: {
    userId: number;
    index: number;
  } | null;
  saved: string;
  error: string;
  dialogChildren: any;
}

export const appInitialState: AppState = {
  closeDialogs: false,
  sideBar: true,
  isMobile: false,
  showPreviousInputs: null,
  saved: '',
  error: '',
  dialogChildren: null,
};

export default function appReducer(state = appInitialState, action: actions.AppActions) {
  switch (action.type) {
    case actions.CLOSE_DIALOGS:
      return {
        ...state,
        closeDialogs: true,
        dialogChildren: null,
      };
    case actions.CLOSE_DIALOGS_SUCCESS:
      return {
        ...state,
        closeDialogs: false,
      };
    case actions.SET_DIALOG_CHILDREN_SUCCESS:
      return {
        ...state,
        dialogChildren: action.payload,
      };
    case actions.TOGGLE_SIDEBAR:
      return {
        ...state,
        sideBar: !state.sideBar,
      };
    case actions.SET_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    case actions.OPEN_PREVIOUS_INPUTS:
      return {
        ...state,
        showPreviousInputs: {
          userId: action.userId,
          index: action.index,
        },
      };
    case actions.CLOSE_PREVIEW_INPUTS:
      return {
        ...state,
        showPreviousInputs: null,
      };
    case actions.SAVE:
      return {
        ...state,
        saved: action.message,
        error: '',
      };
    case actions.SAVE_SUCCESS:
      return {
        ...state,
        saved: '',
      };
    case actions.ERROR:
      return {
        ...state,
        error: action.message,
      };
    case actions.REMOVE_ERROR:
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
}
